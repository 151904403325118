import { Box, styled } from '@pogokid/mui'
import React, { FC, ReactNode } from 'react'
import { TopToolbar } from '~m/designSystem'
import { QuickSearchButton } from '~m/AppUi/components/QuickSearchButton.tsx'

interface SecondaryLayoutWithActionsProps {
  heading: string
  toolbar?: ReactNode
  actionsDrawer: ReactNode
}

const CentrePanel = styled(Box)(`
  flex-grow: 1;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
`)

export const SecondaryLayoutWithActions: FC<
  React.PropsWithChildren<SecondaryLayoutWithActionsProps>
> = ({ heading, toolbar, actionsDrawer, children }) => {
  return (
    <Box sx={{ display: 'flex' }}>
      <CentrePanel>
        {heading && (
          <TopToolbar heading={heading}>
            <QuickSearchButton />
            {toolbar}
          </TopToolbar>
        )}
        {children}
      </CentrePanel>
      {actionsDrawer}
    </Box>
  )
}
