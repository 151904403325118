import { Optional } from '@pogokid/util'

export function signinPath() {
  return '/signin'
}

export function signUpCallbackWithReturnTo(returnUrl: string) {
  return `/auth/callback?return_to=${returnUrl}`
}

export function signinPathWithReturnTo(
  returnUrl: Optional<string>
) {
  if (typeof returnUrl === 'string') {
    return `${signinPath()}?return_to=${returnUrl}`
  } else {
    return signinPath()
  }
}
