import { forwardRef } from 'react'
import { Link as RLink } from 'react-router-dom'
import {
  Link as MuiLink,
  LinkProps as MuiLinkProps,
} from '@pogokid/mui'

declare module '@mui/material/Link/Link' {
  interface LinkPropsColorOverrides {
    neutral: true
  }
}

interface LinkProps extends MuiLinkProps {
  href: string
}

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ href, ...props }, ref) => {
    return (
      <MuiLink
        component={RLink}
        ref={ref}
        {...props}
        to={href}
      />
    )
  }
)
Link.displayName = 'Link'
