import { Optional } from '@pogokid/util'
import { buildEntityKeys } from '@soniq/public-manager'

export const integrationKeys = buildEntityKeys<{}>('integration')
export const integrationConnectionKeys = buildEntityKeys(
  'integration_connection'
)

export const integrationTokenKeys = {
  all: ['integration_token'] as const,
  details: () =>
    [...integrationTokenKeys.all, 'detail'] as const,
  detail: (id: Optional<string>) =>
    [...integrationTokenKeys.details(), id] as const,
}
