import { PropsWithChildren } from 'react'
import {
  ErrorBoundary as SentryErrorBoundary,
  FallbackRender,
} from '@sentry/react'
import { DisplayEmpty } from '~m/AppUi/components/DisplayEmpty.tsx'
import { Button } from '~m/designSystem'

export function ErrorBoundary({ children }: PropsWithChildren) {
  return (
    <SentryErrorBoundary fallback={errorBoundaryFallback}>
      {children}
    </SentryErrorBoundary>
  )
}

const errorBoundaryFallback: FallbackRender = ({ error }) => {
  return (
    <DisplayEmpty
      title="Sorry, we have hit an error"
      description={String(error)}
      actions={<Button href="/dashboard">Goto Dashboard</Button>}
    />
  )
}
