import {
  compileValidationSchema,
  makePropertyCleaner,
  schemaProperties,
} from '@pogokid/schema'
import userSettingSchema from './UserSetting.schema.json'
import { UserSettingSchema } from '../../.types/UserSetting.schema'
import { compileJsonSchema } from '@pogokid/schema/dynamic'

export const validateUserSetting =
  compileValidationSchema<UserSettingSchema>(
    'UserSettingSchema',
    compileJsonSchema(userSettingSchema)
  )

export const userSettingProperties = schemaProperties(
  userSettingSchema
)
export const cleanUserSettingProperties =
  makePropertyCleaner<UserSettingSchema>(userSettingProperties)
