import {
  compileValidationSchema,
  makePropertyCleaner,
  schemaProperties,
} from '@pogokid/schema'
import { TagSchema } from '../../.types/Tag.schema'
import tagSchema from './Tag.schema.json'
import { compileJsonSchema } from '@pogokid/schema/dynamic'

export const validateTag = compileValidationSchema<TagSchema>(
  'TagSchema',
  compileJsonSchema(tagSchema)
)
export const tagProperties = schemaProperties(tagSchema)
export const cleanTagProperties =
  makePropertyCleaner<TagSchema>(tagProperties)
