import { SupabaseClient } from '@pogokid/supabase'
import {
  QueryResponse,
  SingleResponse,
  UpsertSchema,
} from '@soniq/public-resource'
import {
  getById,
  getByIds,
  insertInto,
  updateOne,
  upsertManyInto,
} from '@soniq/public-resource/supabase'
import { OmitCreated, SchemaWithId } from '@soniq/schema'
import {
  TaggedEntitySchema,
  TaggedEntityWithId,
  validateTaggedEntity,
} from '@soniq/schema-util'
import { Database } from '@soniq/util-supabase'

export const taggedEntityCollection = (db: SupabaseClient) =>
  (db as SupabaseClient<Database>).from('tagged_entity')

export async function getTaggedEntityCollection(
  db: SupabaseClient,
  userId: string,
  limitResults = 100
): Promise<QueryResponse<TaggedEntityWithId>> {
  const { data, error } = await taggedEntityCollection(db)
    .select()
    .order('slug')
    .limit(limitResults)
  if (error) throw error
  return { data: data as TaggedEntityWithId[] }
}

export async function getTaggedEntityById(
  db: SupabaseClient,
  taggedEntityId: string
): Promise<SingleResponse<TaggedEntityWithId>> {
  return await getById(
    taggedEntityCollection(db),
    taggedEntityId
  )
}

export async function getTaggedEntitysByIds(
  db: SupabaseClient,
  taggedEntitysIds: string[]
): Promise<QueryResponse<TaggedEntityWithId>> {
  return await getByIds(
    taggedEntityCollection(db),
    taggedEntitysIds
  )
}

export async function createTaggedEntity(
  db: SupabaseClient,
  userId: string,
  taggedEntity: OmitCreated<TaggedEntitySchema>
): Promise<SingleResponse<SchemaWithId<TaggedEntityWithId>>> {
  return insertInto<TaggedEntitySchema>(
    taggedEntityCollection(db),
    {
      ...taggedEntity,
      created_by: userId,
      created_at: new Date().toISOString(),
    },
    validateTaggedEntity
  )
}

export async function setTaggedEntities(
  db: SupabaseClient,
  taggedEntities: UpsertSchema<TaggedEntitySchema>[]
): Promise<QueryResponse<TaggedEntityWithId>> {
  return upsertManyInto<TaggedEntitySchema>(
    taggedEntityCollection(db),
    taggedEntities,
    validateTaggedEntity
  )
}

export async function updateTaggedEntity(
  db: SupabaseClient,
  id: string,
  taggedEntity: Partial<TaggedEntitySchema>
) {
  return updateOne<TaggedEntityWithId>(
    taggedEntityCollection(db),
    id,
    taggedEntity
  )
}
