import {
  compileValidationSchema,
  makePropertyCleaner,
  schemaProperties,
} from '@pogokid/schema'
import invoiceLineSchema from './InvoiceLine.schema.json'
import { InvoiceLineSchema } from '../../.types/InvoiceLine.schema'
import { cleanInvoiceLineOptions } from './clean'
import { compileJsonSchema } from '@pogokid/schema/dynamic'

export const validateInvoiceLine =
  compileValidationSchema<InvoiceLineSchema>(
    'InvoiceLineSchema',
    compileJsonSchema(invoiceLineSchema),
    cleanInvoiceLineOptions
  )

export const invoiceLineProperties = schemaProperties(
  invoiceLineSchema
)

export const cleanInvoiceLineProperties =
  makePropertyCleaner<InvoiceLineSchema>(invoiceLineProperties)
