import { Optional } from '@pogokid/util'
import { SingleResponse } from '@soniq/public-resource'
import { SchemaWithId } from '@soniq/schema'
import {
  QueryClient,
  UseQueryOptions,
} from '@tanstack/react-query'
import { EntityKeys } from './types'

export function findEntityInitialData<
  Schema extends SchemaWithId<unknown>,
  Keys extends EntityKeys<Record<string, any>>
>({
  queryClient,
  entityKeys,
  id,
  idProp = 'id',
}: {
  queryClient: QueryClient
  entityKeys: Keys
  id: Optional<string>
  idProp?: keyof Schema
}): Pick<
  UseQueryOptions<
    SingleResponse<Schema> | null,
    Error,
    SingleResponse<Schema>
  >,
  'initialData' | 'initialDataUpdatedAt'
> {
  function find() {
    const allData = queryClient.getQueriesData<{
      data: Schema | Schema[]
    }>({ queryKey: entityKeys.all })

    for (const [queryKey, response] of allData) {
      if (Array.isArray(response?.data)) {
        const entity = response.data.find(
          (entity) => idProp in entity && id === entity[idProp]
        )
        return {
          initialData: entity
            ? ({ data: entity } as SingleResponse<Schema>)
            : undefined,
          initialDataUpdatedAt: entity
            ? queryClient.getQueryState(queryKey)?.dataUpdatedAt
            : undefined,
        }
      } else if (id === response?.data?.[idProp]) {
        return {
          initialData: response as SingleResponse<Schema>,
          initialDataUpdatedAt:
            queryClient.getQueryState(queryKey)?.dataUpdatedAt,
        }
      }
    }
    return {
      initialData: undefined,
      initialDataUpdatedAt: undefined,
    }
  }
  return {
    initialData: () => find().initialData,
    initialDataUpdatedAt: () => find().initialDataUpdatedAt,
  }
}
