import { SupabaseClient } from '@pogokid/supabase'
import {
  SingleResponse,
  UpsertSchema,
} from '@soniq/public-resource'
import {
  getById,
  insertInto,
  updateOne,
  upsertManyInto,
} from '@soniq/public-resource/supabase'
import { ensureCreatedFields, OmitCreated } from '@soniq/schema'
import {
  InvoiceLineSchema,
  InvoiceLineWithId,
  validateInvoiceLine,
} from '@soniq/schema-payments'
import { Database } from '@soniq/util-supabase'

export const invoiceLineCollection = (db: SupabaseClient) =>
  (db as SupabaseClient<Database>).from('invoice_line')

export async function getInvoiceLineById(
  db: SupabaseClient,
  invoiceLineId: string
): Promise<SingleResponse<InvoiceLineWithId>> {
  return await getById(invoiceLineCollection(db), invoiceLineId)
}

export async function createInvoiceLine(
  db: SupabaseClient,
  userId: string,
  invoiceLine: OmitCreated<InvoiceLineSchema>
): Promise<SingleResponse<InvoiceLineWithId>> {
  const { isValid, cleanedValues } = validateInvoiceLine(
    ensureCreatedFields(userId, invoiceLine)
  )
  if (isValid) {
    return await insertInto(
      invoiceLineCollection(db),
      cleanedValues
    )
  } else {
    throw new Error('Cannot create invoiceLine, invalid data')
  }
}

export async function upsertInvoiceLines(
  db: SupabaseClient,
  invoiceLines: UpsertSchema<InvoiceLineSchema>[]
) {
  const cleanedValue = invoiceLines.map(
    ({ id, ...invoiceLine }): InvoiceLineWithId => {
      const { isValid, cleanedValues } =
        validateInvoiceLine(invoiceLine)
      if (!isValid) {
        throw new Error(
          'Cannot upsert invoice line, invalid data'
        )
      }
      return { id, ...cleanedValues }
    }
  )
  return await upsertManyInto<InvoiceLineSchema>(
    invoiceLineCollection(db),
    cleanedValue
  )
}

export async function updateInvoiceLine(
  db: SupabaseClient,
  id: string,
  invoiceLine: Partial<InvoiceLineSchema>
) {
  return await updateOne<InvoiceLineSchema>(
    invoiceLineCollection(db),
    id,
    invoiceLine
  )
}
