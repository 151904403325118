import {
  compileValidationSchema,
  makePropertyCleaner,
  schemaProperties,
} from '@pogokid/schema'
import profileSchema from './Profile.schema.json'
import { ProfileSchema } from '../../.types/Profile.schema'
import { compileJsonSchema } from '@pogokid/schema/dynamic'

export const validateProfile =
  compileValidationSchema<ProfileSchema>(
    'ProfileSchema',
    compileJsonSchema(profileSchema)
  )

export const profileProperties = schemaProperties(profileSchema)
export const cleanProfileProperties =
  makePropertyCleaner<ProfileSchema>(profileProperties)
