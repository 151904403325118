import { Optional } from '@pogokid/util'
import {
  buildEntityKeys,
  useEntitiesList,
  useEntityById,
} from '@soniq/public-manager'
import { useIntegrationToken } from '@soniq/public-manager-integrate'
import { fetcherFreeAgent } from './fetcherFreeAgent'
import { FreeAgentContact, FreeAgentListResponse } from './types'

const CONTACTS_API_PATH = '/v2/contacts'

export const freeagentContactKeys = buildEntityKeys(
  'freeagent_contact'
)

export async function getFreeAgentContacts(
  accessToken: string
): Promise<
  { contacts: FreeAgentContact[] } & FreeAgentListResponse
> {
  return fetcherFreeAgent(
    CONTACTS_API_PATH,
    accessToken,
    undefined
  )
}

export async function getFreeAgentContact(
  url: string,
  accessToken: string
): Promise<{ contact: FreeAgentContact }> {
  const path = new URL(url!).pathname
  return fetcherFreeAgent(path, accessToken)
}

export function useFreeAgentContacts() {
  const { accessToken } = useIntegrationToken('freeagent')
  return useEntitiesList(freeagentContactKeys.list(), {
    enabled: !!accessToken,
    queryFn: async () => {
      const res = await getFreeAgentContacts(accessToken!)
      return {
        data: res.contacts.map((contact) => ({
          id: contact.url,
          ...contact,
        })),
      }
    },
  })
}

export function useFreeAgentContactByUrl(url: Optional<string>) {
  const { accessToken } = useIntegrationToken('freeagent')
  return useEntityById(
    url,
    freeagentContactKeys.detail(url),
    freeagentContactKeys,
    {
      enabled: !!accessToken && !!url,
      queryFn: async () => {
        const res = await getFreeAgentContact(url!, accessToken!)
        return {
          data: {
            id: res.contact.url,
            ...res.contact,
          },
        }
      },
    }
  )
}
