import {
  compileValidationSchema,
  makePropertyCleaner,
  schemaProperties,
} from '@pogokid/schema'
import invoiceSchema from './Invoice.schema.json'
import { InvoiceSchema } from '../../.types/Invoice.schema'
import { cleanInvoiceOptions } from './clean'
import { compileJsonSchema } from '@pogokid/schema/dynamic'

export const validateInvoice =
  compileValidationSchema<InvoiceSchema>(
    'InvoiceSchema',
    compileJsonSchema(invoiceSchema),
    cleanInvoiceOptions
  )

export const invoiceProperties = schemaProperties(invoiceSchema)

export const cleanInvoiceProperties =
  makePropertyCleaner<InvoiceSchema>(invoiceProperties)
