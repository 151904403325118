function getConfig(): NextRuntimeConfig {
  return {
    publicRuntimeConfig: {
      supabaseUrl: import.meta.env.VITE_SUPABASE_URL!,
      supabaseAnonKey: import.meta.env.VITE_SUPABASE_ANON_KEY!,
      configName:
        import.meta.env.VITE_CONFIG_NAME || 'development',
    },
  }
}

export interface NextRuntimeConfig {
  publicRuntimeConfig: {
    supabaseUrl: string
    supabaseAnonKey: string
    configName: string
  }
}

export interface PublicConfigEnv {
  environment: string
  apiBaseUrl: string
  callbackBaseUrl: string
  freeagentApi: string
}

export const publicConfig = new Map<string, PublicConfigEnv>()

export function getPublicConfig(
  name?: string
): PublicConfigEnv & NextRuntimeConfig['publicRuntimeConfig'] {
  const nextConfig = getConfig() as NextRuntimeConfig
  name = name ?? nextConfig.publicRuntimeConfig.configName
  const config = publicConfig.get(name)
  if (!config) throw new Error(`Public config ${name} not found`)
  return { ...config, ...nextConfig.publicRuntimeConfig }
}

publicConfig.set('development', {
  environment: 'development',
  apiBaseUrl: 'http://localhost:30041/v1',
  callbackBaseUrl: 'http://localhost:30070',
  freeagentApi: 'https://api.sandbox.freeagent.com',
})

publicConfig.set('preview', {
  environment: import.meta.env.VITE_SONIQ_STAGE,
  apiBaseUrl: `https://${
    import.meta.env.VITE_SONIQ_STAGE
  }-api.soniq.studio/v1`,
  callbackBaseUrl: `https://${
    import.meta.env.VITE_SONIQ_STAGE
  }.soniq.studio`,
  freeagentApi: 'https://api.sandbox.freeagent.com',
})

publicConfig.set('production', {
  environment: 'production',
  apiBaseUrl: 'https://api.soniq.studio/v1',
  callbackBaseUrl: 'https://soniq.studio',
  freeagentApi: 'https://api.freeagent.com',
})
