import { useCallback, useRef } from 'react'

class GlobalEventEmitter extends EventTarget {}

export function createEventEmitter() {
  const globalEventEmitter = new GlobalEventEmitter()

  function useEventEmitter<T>(eventName: string) {
    const skipRerender = useRef(false)
    const dispatchEvent = useCallback(
      (eventData: T, skipRender = true) => {
        skipRerender.current = skipRender
        const event = new CustomEvent(eventName, {
          detail: eventData,
        })
        globalEventEmitter.dispatchEvent(event)
      },
      [eventName]
    )
    const addEventListener = useCallback(
      (listener: (data: CustomEvent<T>) => void) => {
        globalEventEmitter.addEventListener(
          eventName,
          listener as EventListener
        )

        // Cleanup subscription on unmount
        return () => {}
      },
      [eventName, skipRerender]
    )

    const removeEventListener = useCallback(
      (listener: (event: CustomEvent<T>) => void) =>
        globalEventEmitter.removeEventListener(
          eventName,
          listener as EventListener
        ),
      [eventName]
    )

    return {
      dispatchEvent,
      addEventListener,
      removeEventListener,
    }
  }

  return { useEventEmitter }
}
