import { useUser } from '@supabase/auth-helpers-react'

export {
  type AuthChangeEvent,
  type SupabaseClient,
} from '@supabase/supabase-js'
export {
  type Session,
  type User,
  useSupabaseClient,
  useUser,
  useSession,
  useSessionContext,
} from '@supabase/auth-helpers-react'

export function useUserId() {
  return useUser()?.id
}

export function useEnsureUserId() {
  const id = useUserId()
  if (!id) throw new Error('User must be available')
  return id
}
