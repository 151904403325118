import { SupabaseClient } from '@pogokid/supabase'
import {
  fetchJson,
  fetchWithAuth,
  SingleResponse,
  UpsertSchema,
} from '@soniq/public-resource'
import {
  getById,
  insertInto,
  updateOne,
  upsertManyInto,
} from '@soniq/public-resource/supabase'
import { OmitCreated, renewCreatedFields } from '@soniq/schema'
import {
  InvoiceLineWithId,
  InvoiceSchema,
  InvoiceWithId,
  PayPaymentProvider,
  validateInvoice,
} from '@soniq/schema-payments'
import { Database } from '@soniq/util-supabase'

export const invoiceCollection = (db: SupabaseClient) =>
  (db as SupabaseClient<Database>).from('invoice')

export async function getInvoiceById(
  db: SupabaseClient,
  invoiceId: string
): Promise<SingleResponse<InvoiceWithId>> {
  return await getById(invoiceCollection(db), invoiceId)
}

export async function createInvoice(
  db: SupabaseClient,
  userId: string,
  invoice: OmitCreated<InvoiceSchema>
): Promise<SingleResponse<InvoiceWithId>> {
  const { isValid, cleanedValues } = validateInvoice(
    renewCreatedFields(userId, invoice)
  )
  if (isValid) {
    return await insertInto<InvoiceSchema>(
      invoiceCollection(db),
      cleanedValues
    )
  } else {
    throw new Error('Cannot create invoice, invalid data')
  }
}

export async function upsertInvoices(
  db: SupabaseClient,
  invoices: UpsertSchema<InvoiceSchema>[]
) {
  return await upsertManyInto<InvoiceSchema>(
    invoiceCollection(db),
    invoices,
    validateInvoice
  )
}

export async function updateInvoice(
  db: SupabaseClient,
  id: string,
  invoice: Partial<InvoiceSchema>
) {
  return await updateOne<InvoiceSchema>(
    invoiceCollection(db),
    id,
    invoice
  )
}

export async function actionInvoice<
  TData extends Record<string, unknown>
>(
  apiBaseUrl: string,
  db: SupabaseClient,
  type: string,
  data: TData
): Promise<SingleResponse<{ ok: boolean }>> {
  const result = await fetchWithAuth<
    { ok: boolean },
    { type: string } & Record<string, unknown>
  >({
    db,
    method: 'post',
    baseUrl: apiBaseUrl,
    endpoint: '/invoice/action',
    body: {
      type,
      ...data,
    },
  })
  if (result.status === 200) {
    return { data: result.data || null }
  } else {
    throw new Error('There was a problem with the action')
  }
}

export async function getPayData(
  apiBaseUrl: string,
  link: string
) {
  const { data, error } = await fetchJson<{
    ok: boolean
    logo_path: string
    invoice: InvoiceWithId
    invoice_lines: InvoiceLineWithId[]
    payment_providers: PayPaymentProvider[]
  }>({
    method: 'get',
    baseUrl: apiBaseUrl,
    endpoint: '/pay',
    query: new URLSearchParams([['link', link]]),
  })
  if (error) throw new Error(error.message)
  return { data }
}
