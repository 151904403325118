import { useAppUiContext } from '~m/AppUi/context/AppUiProvider.tsx'
import { useSnack, useSnackAction } from '@pogokid/mui'

export function PwaStatus() {
  const { pwaOfflineReady, pwaNeedsRefresh } = useAppUiContext()
  return pwaOfflineReady ? (
    <PwaOfflineReady />
  ) : pwaNeedsRefresh ? (
    <PwaInstall />
  ) : null
}

function PwaOfflineReady() {
  useSnack({
    id: 'pwa_offline_ready',
    message: 'App ready to work offline',
    severity: 'info',
  })
  return null
}

function PwaInstall() {
  const { updatePwa } = useAppUiContext()
  const { snackId } = useSnack({
    id: 'pwa_update',
    message: 'New update ready',
    severity: 'info',
    isSticky: true,
  })
  useSnackAction(
    snackId,
    { id: 'pwa_update', label: 'Update' },
    async function (action) {
      console.log('action', action)
      await updatePwa()
    }
  )
  return null
}
