import { newId } from '@pogokid/supabase'
import { ensureCreatedFields } from '@soniq/schema'
import {
  cleanContactRelationshipProperties,
  ContactRelationshipWithId,
  ContactWithId,
} from '@soniq/schema-connection'
import { isNewStudentContact } from './guards'
import {
  ManageStudentAndTheirContactsSchema,
  NewContactOrExistingRelationship,
} from './types'
import { getAllLogger } from '@pogokid/log'

const log = getAllLogger(
  'public-manager-connection:generateContactSubjectAndRelationships'
)

export interface SubjectAndLinksOptions
  extends ManageStudentAndTheirContactsSchema {
  userId: string
}

export function generateContactSubjectAndRelationships({
  userId,
  contacts,
  ...studentData
}: SubjectAndLinksOptions) {
  const subjectId = newId()
  const { newContacts, newContactRelationships } =
    generateSubjectRelationships(userId, subjectId, contacts)
  const student = ensureCreatedFields<ContactWithId>(userId, {
    ...studentData,
    id: subjectId,
    owner_id: userId,
    email: studentData.email ?? null,
    is_student: true,
    meta: {},
  })
  return {
    student,
    newContacts,
    newStudentContacts: newContactRelationships,
  }
}

export function generateSubjectRelationships(
  userId: string,
  subjectId: string,
  contacts: NewContactOrExistingRelationship[]
) {
  const newContacts: ContactWithId[] = []
  const newContactRelationships: ContactRelationshipWithId[] = []
  for (const newContactOrRelationship of contacts) {
    if (isNewStudentContact(newContactOrRelationship)) {
      log.info(
        'New contact for relationship',
        newContactOrRelationship
      )
      const contactId = newId()
      newContacts.push(
        ensureCreatedFields<ContactWithId>(userId, {
          id: contactId,
          name: newContactOrRelationship.name,
          email: newContactOrRelationship.email,
          owner_id: userId,
          is_student: false,
          meta: {},
        })
      )
      // Relationship to the subject contact
      newContactRelationships.push(
        ensureCreatedFields<ContactRelationshipWithId>(userId, {
          id: newId(),
          subject_id: subjectId,
          contact_id: contactId,
          relationship: newContactOrRelationship.relationship,
        })
      )
      // Relationship with the current user
      if (userId !== subjectId) {
        newContactRelationships.push(
          ensureCreatedFields<ContactRelationshipWithId>(
            userId,
            {
              id: newId(),
              subject_id: userId,
              contact_id: contactId,
            }
          )
        )
      }
    } else {
      newContactRelationships.push(
        ensureCreatedFields<ContactRelationshipWithId>(userId, {
          id: newContactOrRelationship.id || newId(),
          ...cleanContactRelationshipProperties({
            ...newContactOrRelationship,
            subject_id: subjectId,
          }),
        })
      )
    }
  }

  return {
    newContactRelationships,
    newContacts,
  }
}
