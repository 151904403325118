import { getPublicConfig } from '~/config'
import { SupabaseClient } from '@supabase/supabase-js'
import { signinPathWithReturnTo } from '~m/auth/paths'

export type ReauthenticateFlowOptions =
  | { type: 'Google' }
  | { type: 'EmailLink'; email: string; returnUrl: string }

export async function reauthenticate(
  supabase: SupabaseClient,
  options: ReauthenticateFlowOptions
) {
  const user = await supabase.auth.getUser()
  if (!user)
    throw new Error('Cannot reauthenticate if no current user')
  const { callbackBaseUrl } = getPublicConfig()
  switch (options.type) {
    case 'EmailLink':
      await supabase.auth.signInWithOtp({
        email: options.email,
        options: {
          emailRedirectTo: `${callbackBaseUrl}${signinPathWithReturnTo(
            options.returnUrl
          )}`,
        },
      })
      break
    // case 'Google':
    //   await reauthenticateWithPopup(
    //     auth.currentUser,
    //     googleProvider
    //   )
    //   break
    default:
      throw new Error('Sign in flow type must be provided')
      break
  }
}
