import {
  createTheme,
  PaletteOptions,
} from '@mui/material/styles'
import { PaletteMode, pxToRem } from '@pogokid/mui'

export const shadows = {
  none: '',
  base: '4px 4px 0px 0px #000',
}
/**
 * Customising MUI Theme
 * Adding new colours:
 * https://mui.com/material-ui/customization/palette/#adding-new-colors
 */
declare module '@mui/material/styles' {
  interface Theme {}

  interface Palette {
    neutral: Palette['primary']
  }

  interface PaletteOptions {
    neutral: PaletteOptions['primary']
  }

  interface PaletteColor {}

  interface SimplePaletteColorOptions {}

  interface ThemeOptions {}
}

declare module '@mui/material/SvgIcon/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    neutral: true
  }
}

// https://coolors.co/ef476f-ffd166-06d6a0-118ab2-073b4c
export const lightPalette = {
  mode: 'light' as PaletteMode,
  text: {
    primary: '#000',
  },
  divider: '#000',
  primary: {
    main: '#ef476f',
    contrastText: '#fff',
  },
  secondary: {
    main: '#14b6ea',
  },
  success: {
    main: '#06d6a0',
  },
  background: {
    default: '#fff',
  },
  neutral: {
    main: '#555',
    dark: '#000',
    light: '#e7eaef',
    contrastText: '#fff',
  },
} satisfies PaletteOptions

export const darkPalette = {
  mode: 'dark' as PaletteMode,
  text: {
    primary: '#fefefe',
  },
  divider: '#000',
  primary: {
    main: '#ef476f',
    // contrastText: '#fff',
  },
  secondary: {
    main: '#14b6ea',
  },
  success: {
    main: '#06d6a0',
  },
  background: {
    default: '#33292b',
    paper: '#211819',
  },
  neutral: {
    main: '#939a99',
    light: '#415855',
    dark: '#e7efed',
    contrastText: '#001f1b',
  },
}

// Create a theme instance.
export const soniqStylesTheme = (mode: PaletteMode) => {
  const palette = mode === 'dark' ? darkPalette : lightPalette
  return createTheme({
    typography: {
      fontFamily: [
        '"DM Sans"',
        '"Helvetica Neue"',
        '"Helvetica"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      h1: {
        fontSize: pxToRem(24),
        fontWeight: 600,
        letterSpacing: '-0.025em',
      },
      h2: {
        fontSize: pxToRem(22),
        fontWeight: 600,
        letterSpacing: '-0.025em',
      },
      h3: {
        fontSize: pxToRem(18),
      },
      h4: {
        fontSize: '1.0rem',
      },
      h5: {
        fontSize: '1.0rem',
      },
      h6: {
        fontSize: '1.0rem',
      },
      /**
       * This is the most prominent subtitle
       */
      subtitle1: {
        fontSize: pxToRem(14),
      },
      /**
       * This is a secondary level subtitle
       */
      subtitle2: {
        fontSize: pxToRem(13),
      },
      /**
       * This is the default body text
       */
      body1: {
        fontSize: pxToRem(14),
        fontWeight: 500,
      },
      /**
       * This is a secondary level which
       * should be used for less prominent
       * and slightly smaller text
       */
      body2: {
        fontSize: pxToRem(13),
      },
      button: {
        fontSize: pxToRem(13),
        textTransform: 'none',
      },
      caption: {
        fontSize: pxToRem(13),
        color: palette.neutral?.main,
      },
    },
    palette,
    shape: {
      borderRadius: 0,
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          html: {
            colorScheme: mode,
          },
        },
      },
      MuiAccordion: {
        defaultProps: {
          elevation: 0,
          disableGutters: true,
        },
        styleOverrides: {
          root: {
            margin: '0',
          },
        },
      },
      MuiAccordionSummary: {
        defaultProps: {},
        styleOverrides: {
          root: {
            padding: '0 0.5rem',
            minHeight: '2rem',
          },
          content: {
            margin: '0.5rem 0',
          },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            padding: '0 0.5rem 0.5rem 0.5rem',
          },
        },
      },
      MuiAppBar: {},
      MuiAutocomplete: {
        styleOverrides: {
          inputRoot: {
            padding: '0 0.5rem',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: 800,
            textTransform: 'uppercase',
          },
          outlined: {
            // boxShadow: 'rgb(0 0 0 / 7%) 0px 1px 1px',
          },
          sizeSmall: {
            padding: `0 ${pxToRem(6)}`,
          },
          sizeMedium: {
            padding: `${pxToRem(2)} ${pxToRem(10)}`,
          },
          sizeLarge: {
            padding: `${pxToRem(4)} ${pxToRem(10)}`,
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {},
          sizeSmall: {
            fontSize: pxToRem(16),
            padding: '4px',
          },
        },
      },
      MuiCardContent: {
        defaultProps: {},
        styleOverrides: {
          root: {
            padding: '0.5rem',
          },
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          root: {
            padding: '0.5rem',
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            fontSize: '1.2rem',
            fontWeight: 600,
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paperAnchorDockedLeft: {},
        },
      },
      // MuiFormControl: {
      //   styleOverrides: {
      //     root: ({ theme }) => ({
      //       zIndex: 1,
      //       backgroundColor: theme.palette.background.default,
      //     }),
      //   },
      // },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontSize: pxToRem(13),
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          outlined: ({ ownerState }) => ({
            transform: ownerState.shrink
              ? 'translate(1rem, -0.5rem) scale(0.75)'
              : 'translate(0.5rem, 0.5rem) scale(1)',
          }),
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            padding: `0.5rem 0.5rem`,
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          input: {
            padding: `0.5rem 0.5rem`,
            paddingRight: 0,
          },
          multiline: {
            padding: 0,
          },
        },
      },
      // MuiTextField: {},
      MuiPaper: {
        styleOverrides: {
          elevation1: {
            backgroundColor: palette.neutral.light,
          },
          elevation2: {
            border: `solid 2px ${palette.divider}`,
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            fontWeight: 500,
          },
        },
      },
      // MuiToolbar: {
      //   styleOverrides: {
      //     root: ({ theme }) => ({
      //       // borderBottom: `solid 1px ${theme.palette.neutral.light}`,
      //     }),
      //     dense: {},
      //   },
      // },
    },
    shadows: [
      'none',
      shadows.none,
      shadows.base,
      shadows.base,
      shadows.base,
      shadows.base,
      shadows.base,
      shadows.base,
      shadows.base,
      shadows.base,
      shadows.base,
      shadows.base,
      shadows.base,
      shadows.base,
      shadows.base,
      shadows.base,
      shadows.base,
      shadows.base,
      shadows.base,
      shadows.base,
      shadows.base,
      shadows.base,
      shadows.base,
      shadows.base,
      shadows.base,
    ],
  })
}
