import { SupabaseClient } from '@pogokid/supabase'
import {
  setEntityMutations,
  setEntityStoreMutations,
} from '@soniq/public-manager'
import {
  locationStore,
  LocationStore,
  profileSettingsStore,
  ProfileSettingsStore,
  profileStore,
  ProfileStore,
  updateContact,
} from '@soniq/public-resource-connection'
import {
  setTaggedEntities,
  updateTaggedEntity,
} from '@soniq/public-resource-util'
import {
  ContactSchema,
  LocationSchema,
  ProfileSchema,
  ProfileSettingsSchema,
} from '@soniq/schema-connection'
import { TaggedEntitySchema } from '@soniq/schema-util'
import { QueryClient } from '@tanstack/react-query'
import {
  contactKeys,
  locationKeys,
  profileKeys,
  profileSettingsKeys,
  taggedEntityKeys,
} from './keys'

export function initConnectionManagerEntities(
  queryClient: QueryClient,
  db: SupabaseClient
) {
  // Contact
  setEntityMutations<ContactSchema, typeof contactKeys>({
    queryClient,
    entityKeys: contactKeys,
    upsertEntities: async ({ userId, data }) => {
      throw new Error(`Upsert not implemented`)
    },
    updateEntity: async ({ userId, data }) =>
      updateContact(db, data.id, data),
    deleteEntity: async (id) => {
      throw new Error(`Delete Not implemented: ${id}`)
    },
  })

  // Location
  setEntityStoreMutations<
    LocationSchema,
    typeof locationKeys,
    LocationStore
  >({
    queryClient,
    entityKeys: locationKeys,
    store: locationStore,
    db,
  })

  // Profile
  setEntityStoreMutations<
    ProfileSchema,
    typeof profileKeys,
    ProfileStore
  >({
    queryClient,
    entityKeys: profileKeys,
    store: profileStore,
    db,
  })

  // Profile Settings
  setEntityStoreMutations<
    ProfileSettingsSchema,
    typeof profileSettingsKeys,
    ProfileSettingsStore
  >({
    queryClient,
    entityKeys: profileSettingsKeys,
    store: profileSettingsStore,
    db,
  })

  // Tagged Entity
  setEntityMutations<
    TaggedEntitySchema,
    typeof taggedEntityKeys
  >({
    queryClient,
    entityKeys: taggedEntityKeys,
    upsertEntities: async ({ data }) =>
      setTaggedEntities(db, data),
    updateEntity: async ({ data }) =>
      updateTaggedEntity(db, data.id, data),
    deleteEntity: async (id) => {
      throw new Error(`Delete Not implemented: ${id}`)
    },
  })
}
