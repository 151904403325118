import { StepContent, Typography } from '@mui/material'
import { isArrayWithLength } from '@pogokid/util'
import React, {
  createContext,
  FC,
  type PropsWithChildren,
  useContext,
  useMemo,
} from 'react'
import {
  Grid,
  Box,
  Stepper,
  Step,
  StepLabel,
} from '@pogokid/mui'
import { Button } from '~m/designSystem'

interface WorkflowContextState {
  steps: WorkflowStep[]
  activeStepId?: string | number
  activeStepIndex: number
}

const WorkflowContext = createContext<WorkflowContextState>({
  steps: [],
  activeStepIndex: 0,
})

export interface WorkflowStepAction {
  id: string
  label: string
  disabled?: boolean
}

export interface WorkflowStep {
  id: string | number
  label: string
  description?: string
  actions?: WorkflowStepAction[]
}

export interface WorkflowProps {
  steps: WorkflowStep[]
  activeStepId?: string | number
  onStepAction?: (step: string | number, action: string) => void
}

export function Workflow({
  activeStepId,
  steps,
  children,
  onStepAction,
}: PropsWithChildren<WorkflowProps>) {
  const activeStepIndex = steps.findIndex(
    ({ id }) => id === activeStepId
  )
  const value = useMemo<WorkflowContextState>(() => {
    return {
      steps,
      activeStepId,
      activeStepIndex,
    }
  }, [activeStepId, activeStepIndex, steps])

  return (
    <WorkflowContext.Provider value={value}>
      <Grid container spacing={2}>
        <Grid item md={9}>
          {children}
        </Grid>
        <Grid item md={3}>
          <Box
            m={4}
            display="flex"
            justifyContent="center"
            position="sticky"
            top="10vmax"
          >
            <Stepper
              orientation={'vertical'}
              activeStep={activeStepIndex}
            >
              {steps.map(
                ({ id, label, description, actions }) => (
                  <Step key={id}>
                    <StepLabel>{label}</StepLabel>
                    {(description ||
                      isArrayWithLength(actions)) && (
                      <StepContent>
                        {description && (
                          <Typography>{description}</Typography>
                        )}
                        <Box sx={{ mb: 2 }}>
                          {isArrayWithLength(actions)
                            ? actions.map((action) => (
                                <Button
                                  key={action.id}
                                  variant="contained"
                                  disabled={action.disabled}
                                  onClick={() =>
                                    onStepAction?.(id, action.id)
                                  }
                                  sx={{ mt: 1, mr: 1 }}
                                >
                                  {action.label}
                                </Button>
                              ))
                            : null}
                        </Box>
                      </StepContent>
                    )}
                  </Step>
                )
              )}
            </Stepper>
          </Box>
        </Grid>
      </Grid>
    </WorkflowContext.Provider>
  )
}
interface WorkflowSectionProps {
  id: string | number
}

export const WorkflowSection: FC<
  PropsWithChildren<WorkflowSectionProps>
> = ({ id, children }) => {
  const { activeStepId } = useContext(WorkflowContext)
  return id === activeStepId ? <Box p={2}>{children}</Box> : null
}
