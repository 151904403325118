import React, { FC, useCallback } from 'react'
import {
  SnackbarItemAction,
  SnackbarItemCloseReason,
  SnackbarItemState,
} from './types'
import { Alert, Button, Grow, Stack } from '@mui/material'
import { useTimeoutFn } from '@pogokid/react-hooks'

interface SnackbarItemProps {
  snack: SnackbarItemState

  onClose(snackId: string, reason: SnackbarItemCloseReason): void

  onExited(snackId: string): void

  onAction(snackId: string, action: SnackbarItemAction): void
}

export const SnackbarItem: FC<SnackbarItemProps> = ({
  snack,
  onClose,
  onExited,
  onAction,
}) => {
  const onSnackTimeout = useCallback(() => {
    if (!snack.isSticky) {
      onClose(snack.id, 'timeout')
    }
  }, [snack, onClose])
  useTimeoutFn(onSnackTimeout, 6000)

  const onGrowExited = useCallback(() => {
    onExited(snack.id)
  }, [snack, onExited])

  return (
    <Grow
      key={snack.id}
      in={snack.isOpen}
      onExited={onGrowExited}
    >
      <Alert
        data-testid={`snackbar-item-${snack.id}`}
        severity={snack.severity ?? 'info'}
        variant={'filled'}
        action={
          snack.actions || snack.isSticky ? (
            <Stack>
              {snack.actions.map((action) => (
                <Button
                  key={action.id}
                  data-testid={`snackbar-item-action-${snack.id}-${action.id}`}
                  color="inherit"
                  size="small"
                  onClick={() => {
                    onAction(snack.id, action)
                    onClose(snack.id, 'action')
                  }}
                >
                  {action.label}
                </Button>
              ))}
              <Button
                color="inherit"
                size="small"
                onClick={() => {
                  onClose(snack.id, 'action')
                }}
              >
                close
              </Button>
            </Stack>
          ) : null
        }
      >
        {snack.message}
      </Alert>
    </Grow>
  )
}
