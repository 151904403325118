import { SupabaseClient } from '@pogokid/supabase'
import {
  setEntityMutations,
  setEntityStoreMutations,
} from '@soniq/public-manager'
import {
  actionInvoice,
  PriceStore,
  priceStore,
  updateInvoice,
  updateInvoiceLine,
  upsertInvoiceLines,
  upsertInvoices,
} from '@soniq/public-resource-payments'
import {
  InvoiceLineSchema,
  InvoiceSchema,
  PriceSchema,
} from '@soniq/schema-payments'
import { QueryClient } from '@tanstack/react-query'
import { invoiceKeys, invoiceLineKeys, priceKeys } from './keys'

export function initPaymentsManagerEntities(
  apiBaseUrl: string,
  queryClient: QueryClient,
  db: SupabaseClient
) {
  // Invoice
  setEntityMutations<InvoiceSchema, typeof invoiceKeys>({
    queryClient,
    entityKeys: invoiceKeys,
    upsertEntities: async ({ data }) => {
      return upsertInvoices(db, data)
    },
    updateEntity: async ({ data }) => {
      return updateInvoice(db, data.id, data)
    },
    deleteEntity: async (id) => {
      throw new Error(`Delete not implemented: ${id}`)
    },
    actionEntity: async (id, action) => {
      const { type, ...data } = action as any
      return actionInvoice(apiBaseUrl, db, type, data)
    },
  })

  // Invoice Line
  setEntityMutations<InvoiceLineSchema, typeof invoiceLineKeys>({
    queryClient,
    entityKeys: invoiceLineKeys,
    upsertEntities: async ({ data }) => {
      return upsertInvoiceLines(db, data)
    },
    updateEntity: async ({ data }) => {
      return updateInvoiceLine(db, data.id, data)
    },
    deleteEntity: async (id) => {
      throw new Error(`Delete not implemented: ${id}`)
    },
  })

  // Price
  setEntityStoreMutations<
    PriceSchema,
    typeof priceKeys,
    PriceStore
  >({
    queryClient,
    entityKeys: priceKeys,
    store: priceStore,
    db,
  })
}
