import { Optional } from '@pogokid/util'
import { buildEntityKeys } from '@soniq/public-manager'

export const onboardSessionKeys = {
  all: ['onboard_session'] as const,
  current: () => [...onboardSessionKeys.all, 'current'] as const,
}

export const paymentKeys = buildEntityKeys<{
  invoiceId: string
}>('payment')

export const portalSessionKeys = {
  all: ['portal_session'] as const,
  current: () => [...portalSessionKeys.all, 'current'] as const,
}

export const profileSubscriptionKeys = buildEntityKeys<{
  active: Optional<boolean>
}>('profile_subscription')

export const invoiceKeys = buildEntityKeys<{
  userId: Optional<string>
  contactId: Optional<string>
}>('invoice')

export const invoiceActivityKeys = buildEntityKeys<{
  invoice_id: string
}>('invoice_activity')

export const priceKeys = buildEntityKeys<{
  userId?: Optional<string>
}>('price')

export const invoiceLineKeys = buildEntityKeys<{
  invoiceId: Optional<string>
}>('invoice_line')

export const paymentProviderAccountKeys = buildEntityKeys<{
  profileId: Optional<string>
}>('payment_provider_account')

export const subscriptionFeatureKeys = buildEntityKeys(
  'subscription_feature'
)

export const subscriptionPlanKeys = buildEntityKeys(
  'subscription_plan'
)

export const subscriptionPlanFeatureKeys = buildEntityKeys(
  'subscription_plan_feature'
)
