import { SupabaseClient } from '@supabase/supabase-js'

export async function updatePasswordForUser(
  db: SupabaseClient,
  newPassword: string
) {
  const { error } = await db.auth.updateUser({
    password: newPassword,
  })
  if (error) {
    throw error
  }
}
