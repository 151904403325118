import { SupabaseClient } from '@pogokid/supabase'
import { SingleResponse } from '@soniq/public-resource'
import {
  insertInto,
  SupabaseReadWriteStore,
  updateOne,
} from '@soniq/public-resource/supabase'
import { ensureCreatedFields, OmitCreated } from '@soniq/schema'
import {
  PriceSchema,
  PriceWithId,
  validatePrice,
} from '@soniq/schema-payments'
import { Database } from '@soniq/util-supabase'

export class PriceStore extends SupabaseReadWriteStore<
  'price',
  PriceSchema
> {
  static new() {
    return new PriceStore('price', validatePrice)
  }
}

export const priceCollection = (db: SupabaseClient) =>
  (db as SupabaseClient<Database>).from('price')

export async function createPrice(
  db: SupabaseClient,
  userId: string,
  price: OmitCreated<PriceSchema>
): Promise<SingleResponse<PriceWithId>> {
  const { isValid, cleanedValues } = validatePrice(
    ensureCreatedFields(userId, price)
  )
  if (isValid) {
    return await insertInto(priceCollection(db), cleanedValues)
  } else {
    throw new Error('Cannot create price, invalid data')
  }
}

export async function updatePrice(
  db: SupabaseClient,
  id: string,
  price: Partial<PriceSchema>
): Promise<SingleResponse<PriceWithId>> {
  return await updateOne<PriceSchema>(
    priceCollection(db),
    id,
    price
  )
}
