import {
  compileValidationSchema,
  makePropertyCleaner,
  schemaProperties,
} from '@pogokid/schema'
import { InvoiceActivitySchema } from '../../.types/InvoiceActivity.schema'
import schema from './InvoiceActivity.schema.json'
import { compileJsonSchema } from '@pogokid/schema/dynamic'

export const validateInvoiceActivity =
  compileValidationSchema<InvoiceActivitySchema>(
    'InvoiceActivitySchema',
    compileJsonSchema(schema)
  )

export const invoiceActivityProperties = schemaProperties(schema)
export const cleanInvoiceActivityProperties =
  makePropertyCleaner<InvoiceActivitySchema>(
    invoiceActivityProperties
  )
