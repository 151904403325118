import {
  compileValidationSchema,
  makePropertyCleaner,
  schemaProperties,
} from '@pogokid/schema'
import scheduleInstanceSchema from './ScheduleInstance.schema.json'
import { ScheduleInstanceSchema } from '../../.types/ScheduleInstance.schema'
import { cleanScheduleInstanceOptions } from './clean'
import { compileJsonSchema } from '@pogokid/schema/dynamic'

export const validateScheduleInstance =
  compileValidationSchema<ScheduleInstanceSchema>(
    'ScheduleInstanceSchema',
    compileJsonSchema(scheduleInstanceSchema),
    cleanScheduleInstanceOptions
  )

export const scheduleInstanceProperties = schemaProperties(
  scheduleInstanceSchema
)

export const cleanScheduleInstanceProperties =
  makePropertyCleaner<ScheduleInstanceSchema>(
    scheduleInstanceProperties
  )
