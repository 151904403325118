import { getVerboseLogger } from '@pogokid/log'
import { has, set, update } from '@pogokid/util/lodash'
import type {
  DefinedError,
  SchemaObject,
  ValidateFunction,
} from 'ajv'
import { cleanValues, CleanValuesOptions } from './cleanValues'
import { AnyRecord } from '@pogokid/util'

const verbose = getVerboseLogger('ui:forms:validation')

export function schemaWithId(
  schema: SchemaObject
): SchemaObject {
  let result = schema
  if (
    has('properties', schema) &&
    !has('properties.id', schema)
  ) {
    result = update(
      'required',
      (arr: string[]) => ['id', ...arr],
      result
    )
    result = set(
      'properties.id',
      {
        type: 'string',
      },
      result
    )
  }
  return result
}

export interface ValidateSchemaOptions {
  allowPartial?: boolean
}

export interface ValidateSchemaResult<T extends AnyRecord> {
  isValid: boolean
  cleanedValues: T
  errors?: Record<string, string>
}

export type ValidateSchemaFunction<Schema extends AnyRecord> = (
  data: unknown,
  options?: ValidateSchemaOptions
) => ValidateSchemaResult<Schema>

/**
 * Compile a JSON schema once to use multiple times
 * with different values.
 */
export function compileValidationSchema<T extends AnyRecord>(
  id: string,
  validate: ValidateFunction<unknown>,
  cleanValuesOptions: CleanValuesOptions = {}
) {
  return (
    values: T | unknown,
    options: ValidateSchemaOptions = {}
  ): ValidateSchemaResult<T> => {
    const cleanedValues = cleanValues(values as T, {
      ...cleanValuesOptions,
    })
    validate(cleanedValues)
    const definedErrors = validate.errors as DefinedError[]

    // Filter the errors which we have got back from the validate function
    // to only include the onces which affect validity
    const validityErrors = definedErrors?.filter((error) => {
      return !(
        options.allowPartial && error.keyword === 'required'
      )
    })
    const isValid = validityErrors
      ? validityErrors.length === 0
      : true
    const errors = validityErrors?.reduce((result, item) => {
      let field: string = item.instancePath
        .replace(/^\//, '')
        .replace(/\//g, '.')
        .replace(/\.(\d+)/g, '[$1]')
      let message = item.message || ''
      switch (item.keyword) {
        case 'type':
          message = 'required'
          break
        case 'required':
          field = item.params.missingProperty || field
          message = 'required'
          break
      }
      result[field] = message
      return result
    }, {} as Record<string, string>)
    verbose('Validate:', id, {
      isValid,
      errors,
      values,
      cleanedValues,
    })
    return {
      isValid,
      cleanedValues,
      errors,
    }
  }
}
