import { compileValidationSchema } from '@pogokid/schema'
import { ScheduleException } from '../../.types/Schedule.schema'
import { compileJsonSchema } from '@pogokid/schema/dynamic'
import schema from '../schedule/Schedule.schema.json'

export const validateScheduleException =
  compileValidationSchema<ScheduleException>(
    'ScheduleException',
    compileJsonSchema(schema.definitions.ScheduleException)
  )
