import {
  compileValidationSchema,
  makePropertyCleaner,
  schemaProperties,
} from '@pogokid/schema'
import { IntegrationConnectionSchema } from '../../.types/IntegrationConnection.schema'
import integrationConnectionSchema from './IntegrationConnection.schema.json'
import { compileJsonSchema } from '@pogokid/schema/dynamic'

export const validateIntegrationConnection =
  compileValidationSchema<IntegrationConnectionSchema>(
    'IntegrationConnectionSchema',
    compileJsonSchema(integrationConnectionSchema)
  )

export const integrationConnectionProperties = schemaProperties(
  integrationConnectionSchema
)
export const cleanIntegrationConnectionProperties =
  makePropertyCleaner<IntegrationConnectionSchema>(
    integrationConnectionProperties
  )
