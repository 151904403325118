import { SupabaseClient } from '@pogokid/supabase'
import { Optional } from '@pogokid/util'
import {
  fetchWithAuth,
  QueryResponse,
  SingleResponse,
} from '@soniq/public-resource'
import { IntegrationSchema } from '@soniq/schema-integrate'
import { Database } from '@soniq/util-supabase'

const integrationConnectionCollection = (db: SupabaseClient) =>
  (db as SupabaseClient<Database>).from('integration')

export async function getIntegrations(
  db: SupabaseClient
): Promise<QueryResponse<IntegrationSchema>> {
  const { data } = await integrationConnectionCollection(db)
    .select<'*', IntegrationSchema>()
    .throwOnError()
  return { data: data ?? [] }
}

export async function getIntegrationByHandle(
  db: SupabaseClient,
  handle: string
): Promise<SingleResponse<IntegrationSchema>> {
  const { data } = await integrationConnectionCollection(db)
    .select<'*', IntegrationSchema>()
    .eq('handle', handle)
    .maybeSingle()
    .throwOnError()
  return { data }
}

export interface AddIntegrationResponseData {
  url: string
}

export async function addIntegration(
  apiBaseUrl: string,
  db: SupabaseClient,
  integrationHandle: string,
  profileId: string
): Promise<SingleResponse<AddIntegrationResponseData>> {
  const { data, error } =
    await fetchWithAuth<AddIntegrationResponseData>({
      db,
      baseUrl: apiBaseUrl,
      endpoint: `/integration/${integrationHandle}/${profileId}`,
      method: 'post',
    })
  if (error) {
    throw new Error('Invite not ok')
  } else {
    return { data }
  }
}

interface IntegrationTokenResponse {
  integration_id: string
  exists: boolean
  access_token: string
  expires_at: string
}

export async function getIntegrationToken(
  apiBaseUrl: string,
  db: SupabaseClient,
  integrationHandle: string,
  profileId: string
): Promise<SingleResponse<IntegrationTokenResponse>> {
  const { data, error } =
    await fetchWithAuth<IntegrationTokenResponse>({
      db,
      method: 'get',
      baseUrl: apiBaseUrl,
      endpoint: `/integration/${integrationHandle}/${profileId}/token`,
    })
  if (error) {
    throw new Error('Invite not ok')
  } else {
    return { data }
  }
}

interface CompleteIntegrationPostData {
  state: string
  code: string
}

export async function completeIntegration(
  apiBaseUrl: string,
  db: SupabaseClient,
  integrationHandle: string,
  profileId: string,
  state: Optional<string>,
  code: string
): Promise<SingleResponse<null>> {
  const { data, error } = await fetchWithAuth<
    null,
    CompleteIntegrationPostData
  >({
    db,
    baseUrl: apiBaseUrl,
    endpoint: `/integration/${integrationHandle}/${profileId}/complete`,
    method: 'post',
    body: {
      code,
      state: state || '',
    },
  })
  if (error) {
    throw new Error('Invite not ok')
  } else {
    return { data }
  }
}
