import { SupabaseClient } from '@pogokid/supabase'
import { SupabaseReadWriteDeleteStore } from '@soniq/public-resource/supabase'
import {
  ScheduleSchema,
  validateSchedule,
} from '@soniq/schema-scheduling'
import { Database } from '@soniq/util-supabase'

export class ScheduleStore extends SupabaseReadWriteDeleteStore<
  'schedule',
  ScheduleSchema
> {
  static new() {
    return new ScheduleStore('schedule', validateSchedule)
  }
}

export const scheduleCollection = (db: SupabaseClient) =>
  (db as SupabaseClient<Database>).from('schedule')
