import {
  useSession,
  useSupabaseClient,
} from '@pogokid/supabase/react'
import { Optional } from '@pogokid/util'
import { useAsyncQueryResponse } from '@soniq/public-manager'
import { ContactWithId } from '@soniq/schema-connection'
import { useContactById } from './contact'

export function useCurrentUser() {
  const session = useSession()
  return useContactById(session?.user.id)
}

/**
 * Get the users who are a teacher of the passed in userId.
 * FIXME: DT 2023-10-14 update to use contact relationships
 */
export function useTeacherUsersByStudentUserId(
  userId: Optional<string>
) {
  const db = useSupabaseClient()
  return useAsyncQueryResponse<ContactWithId>(async () => {
    if (userId) {
      // const { data: students } = await studentsByStudentUserId(
      //   db,
      //   userId
      // )
      // const teacherIds = (students || [])
      //   .map((student) => student.teacherUserId)
      //   .filter(isString)
      // return await getUsersByIds(db, teacherIds)
      return { data: [] }
    }
    return null
  }, [])
}
