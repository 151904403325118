import { compileValidationSchema } from '@pogokid/schema'
import { InviteSchema } from '../../.types/Invite.schema'
import { compileJsonSchema } from '@pogokid/schema/dynamic'
import inviteSchema from './Invite.schema.json'

export const validateInvite =
  compileValidationSchema<InviteSchema>(
    'InviteSchema',
    compileJsonSchema(inviteSchema)
  )
