import {
  compileValidationSchema,
  makePropertyCleaner,
  schemaProperties,
} from '@pogokid/schema'
import { TaggedEntitySchema } from '../../.types/TaggedEntity.schema'
import taggedEntitySchema from './TaggedEntity.schema.json'
import { compileJsonSchema } from '@pogokid/schema/dynamic'

export const validateTaggedEntity =
  compileValidationSchema<TaggedEntitySchema>(
    'TaggedEntitySchema',
    compileJsonSchema(taggedEntitySchema)
  )
export const taggedEntityProperties = schemaProperties(
  taggedEntitySchema
)
export const cleanTaggedEntityProperties =
  makePropertyCleaner<TaggedEntitySchema>(taggedEntityProperties)
