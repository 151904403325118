import { createContext, FC, useContext, useMemo } from 'react'

interface PaymentsProviderState {}

interface PaymentsContextProps extends PaymentsProviderState {
  apiBaseUrl: string
}

interface PaymentsProviderProps {
  apiBaseUrl: string
}

const PaymentsContext = createContext<PaymentsContextProps>({
  apiBaseUrl: 'http://localhost:30041/v1',
})

export function usePaymentsManager() {
  return useContext(PaymentsContext)
}

export const PaymentsManagerProvider: FC<
  React.PropsWithChildren<PaymentsProviderProps>
> = ({ children, apiBaseUrl }) => {
  const value = useMemo((): PaymentsContextProps => {
    return {
      apiBaseUrl,
    }
  }, [apiBaseUrl])

  return (
    <PaymentsContext.Provider value={value}>
      {children}
    </PaymentsContext.Provider>
  )
}
