import './instrument.ts'
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import ClientLayout from '~m/AppUi/ClientLayout.tsx'

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ClientLayout>
      <App />
    </ClientLayout>
  </React.StrictMode>
)
