import { getPublicConfig } from '~/config'

export interface FetcherFreeAgentOptions {
  per_page?: number
}

export const fetcherFreeAgent = async (
  path: string,
  accessToken: string,
  body?: any,
  { per_page }: FetcherFreeAgentOptions = {}
) => {
  const query = new URLSearchParams({
    per_page: String(per_page || '100'),
  })
  const { freeagentApi } = getPublicConfig()
  const req = new Request(freeagentApi + path + '?' + query, {
    method: body ? 'POST' : 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: body ? JSON.stringify(body) : undefined,
  })
  const res = await fetch(req)
  const json = await res.json()
  if (res.ok) {
    return {
      ...json,
      perPage: per_page || 100,
      page: 1,
    }
  } else {
    throw new Error(
      json.errors?.error?.message || 'Error sending request'
    )
  }
}
