import { ValidateSchemaFunction } from '@pogokid/schema'
import {
  PostgrestQueryBuilder,
  SupabaseClient,
} from '@pogokid/supabase'
import { Optional } from '@pogokid/util'
import {
  CreatedFields,
  DeletedFields,
  OmitCreated,
  omitCreatedFields,
  renewCreatedFields,
  SchemaWithId,
} from '@soniq/schema'
import { Database } from '@soniq/util-supabase'
import { QueryResponse, SingleResponse } from './src/Response'
import { UpsertSchema } from './src/types'
import { isString } from '@pogokid/util/lodash'

type RowOf<Table extends keyof Database['public']['Tables']> =
  Database['public']['Tables'][Table]['Row']

export type SupabaseReadStoreTable =
  keyof Database['public']['Tables']

export type SupabaseReadStoreOf<Schema extends CreatedFields> =
  SupabaseReadStore<SupabaseReadStoreTable, Schema>

export class SupabaseReadStore<
  Table extends SupabaseReadStoreTable,
  Schema extends CreatedFields
> {
  private tableName: Table
  protected columns: Array<keyof Schema> | undefined

  constructor(tableName: Table) {
    this.tableName = tableName
  }

  protected getTable(db: SupabaseClient) {
    return (db as SupabaseClient<Database>).from(this.tableName)
  }

  protected getColumns(): string[] | undefined {
    return this.columns?.filter(isString) as string[] | undefined
  }

  protected getSelectStatement(
    joins: string = ''
  ): string | undefined {
    const columns = this.getColumns()?.join(',')
    return columns
      ? columns + (joins ? `,${joins}` : '')
      : undefined
  }

  /**
   * Map each row from query data into the Schema.
   *
   * This is useful if you need to manipulate the row
   * data before returning.
   *
   * @param data
   * @protected
   */
  protected mapQueryData<
    Returns extends SchemaWithId<Schema> = SchemaWithId<Schema>
  >(data: Optional<Array<RowOf<Table> | Schema>>): Returns[] {
    return (data as unknown as Returns[]) ?? []
  }

  protected async runQuery<
    Data extends SchemaWithId<Schema> = SchemaWithId<Schema>
  >(
    db: SupabaseClient,
    query: (
      table: ReturnType<typeof this.getTable>
    ) => Promise<{ data: null | Data[]; error: unknown }>
  ): Promise<QueryResponse<Data>> {
    const { data, error } = await query(this.getTable(db))
    if (error) throw error
    return { data: this.mapQueryData<Data>(data) }
  }

  async fetchById(db: SupabaseClient, id: string) {
    return await getById<SchemaWithId<Schema>>(
      this.getTable(db),
      id,
      {
        columns: this.getColumns(),
      }
    )
  }

  async fetchByIds(db: SupabaseClient, ids: string[]) {
    return await getByIds<SchemaWithId<Schema>>(
      this.getTable(db),
      ids,
      {
        columns: this.getColumns(),
      }
    )
  }

  async fetchQuery(
    db: SupabaseClient,
    filters: Partial<RowOf<Table>> = {}
  ) {
    return this.runQuery<SchemaWithId<Schema>>(
      db,
      async (table) =>
        await table
          .select<string, SchemaWithId<Schema>>(
            this.getSelectStatement()
          )
          .match(filters)
    )
  }
}

export class SupabaseReadWriteStore<
  Table extends keyof Database['public']['Tables'],
  Schema extends CreatedFields
> extends SupabaseReadStore<Table, Schema> {
  readonly validate: ValidateSchemaFunction<Schema>

  constructor(
    tableName: Table,
    validate: ValidateSchemaFunction<Schema>
  ) {
    super(tableName)
    this.validate = validate
  }

  async create(
    db: SupabaseClient,
    userId: string,
    data: OmitCreated<Schema>
  ) {
    return await insertInto<Schema>(
      this.getTable(db),
      renewCreatedFields<Schema>(userId, data),
      this.validate
    )
  }

  async update(
    db: SupabaseClient,
    { id, ...data }: SchemaWithId<Partial<Schema>>
  ) {
    return updateOne<Schema>(
      this.getTable(db),
      id,
      data as unknown as Partial<Schema>
    )
  }

  async upsert(
    db: SupabaseClient,
    data: UpsertSchema<Schema>[]
  ) {
    return upsertManyInto<Schema>(
      this.getTable(db),
      data,
      this.validate as ValidateSchemaFunction<Schema>
    )
  }
}

export class SupabaseReadWriteDeleteStore<
  Table extends keyof Database['public']['Tables'],
  Schema extends CreatedFields & DeletedFields
> extends SupabaseReadWriteStore<Table, Schema> {
  async delete(db: SupabaseClient, ids: string[]) {
    return deleteMany<Schema>(this.getTable(db), ids)
  }
}

export interface SupabaseSelectOptions {
  columns?: string[]
}

export async function getById<Schema>(
  from: PostgrestQueryBuilder<never, never>,
  id: string,
  options: SupabaseSelectOptions = {}
): Promise<SingleResponse<Schema>> {
  const { data, error } = await from
    .select<string, Schema>(options.columns?.join(','))
    .eq('id', id)
    .limit(1)
    .maybeSingle()
  if (error) throw error
  return { data }
}

export async function getByIds<Schema>(
  from: PostgrestQueryBuilder<never, never>,
  ids: string[],
  options: SupabaseSelectOptions = {}
): Promise<QueryResponse<Schema>> {
  const { data, error } = await from
    .select<string, Schema>(options.columns?.join(','))
    .in('id', ids)
  if (error) throw error
  return { data }
}

export async function queryByCreatedBy<Schema>(
  from: PostgrestQueryBuilder<never, never>,
  created_by: string
): Promise<QueryResponse<Schema>> {
  const { data, error } = await from
    .select()
    .eq('created_by', created_by)
  if (error) throw error
  return { data }
}

export async function insertInto<
  Schema extends Record<never, never>
>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  from: PostgrestQueryBuilder<any, any>,
  insertData: Schema,
  validate?: ValidateSchemaFunction<Schema>
): Promise<SingleResponse<SchemaWithId<Schema>>> {
  if (typeof validate === 'function') {
    const { isValid, cleanedValues, errors } =
      validate(insertData)
    if (!isValid) {
      throw new Error(
        `Cannot insert, invalid: ${JSON.stringify(
          errors,
          null,
          2
        )}`
      )
    }
    insertData = cleanedValues
  }
  const { data, error } = await from
    .insert(insertData)
    .select<'*', SchemaWithId<Schema>>()
    .single()
  if (error) throw error
  return { data }
}

export async function upsertOne<
  Schema extends SchemaWithId<unknown>
>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  from: PostgrestQueryBuilder<any, any>,
  upsertData: Schema
): Promise<SingleResponse<Schema>> {
  const { data, error } = await from
    .upsert(upsertData)
    .select<'*', Schema>()
    .single()
  if (error) throw error
  return { data }
}

export async function upsertManyInto<
  Schema extends CreatedFields
>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  from: PostgrestQueryBuilder<any, any>,
  upsertData: UpsertSchema<Schema>[],
  validate?: ValidateSchemaFunction<Schema>
): Promise<QueryResponse<SchemaWithId<Schema>>> {
  if (typeof validate === 'function') {
    upsertData = upsertData.map(({ id, ...data }) => {
      const { isValid, cleanedValues, errors } = validate(data, {
        allowPartial: true,
      })
      if (!isValid) {
        throw new Error(
          `Cannot upsert, invalid: ${JSON.stringify(
            errors,
            null,
            2
          )}`
        )
      }
      return { id, ...cleanedValues } as UpsertSchema<Schema>
    })
  }
  const { data, error } = await from
    .upsert(upsertData)
    .select<'*', SchemaWithId<Schema>>()
  if (error) throw error
  return { data }
}

export async function updateOne<Schema extends Record<any, any>>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  from: PostgrestQueryBuilder<any, any>,
  id: string,
  updateData: Partial<Schema>
): Promise<SingleResponse<SchemaWithId<Schema>>> {
  const dataNoCreatedFields = omitCreatedFields(updateData)
  const { error, data } = await from
    .update(dataNoCreatedFields)
    .eq('id', id)
    .select<'*', SchemaWithId<Schema>>()
    .single()
  if (error) throw error
  return { data }
}

export async function updateMany<Schema>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  from: PostgrestQueryBuilder<any, any>,
  updateData: Array<SchemaWithId<Partial<Schema>>>
): Promise<QueryResponse<SchemaWithId<Schema>>> {
  const { error, data } = await from
    .upsert(updateData)
    .select<'*', SchemaWithId<Schema>>()
  if (error) throw error
  return { data }
}

export async function deleteMany<Schema extends DeletedFields>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  from: PostgrestQueryBuilder<any, any>,
  ids: string[]
) {
  const updateData = ids.map(
    (id): SchemaWithId<DeletedFields> => ({
      id,
      deleted_at: new Date().toISOString(),
    })
  )
  return updateMany<Schema>(
    from,
    updateData as SchemaWithId<Schema>[]
  )
}

export async function deletePermanentMany(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  from: PostgrestQueryBuilder<any, any>,
  ids: string[]
): Promise<SingleResponse<null>> {
  const { data, error } = await from.delete().in('id', ids)
  if (error) throw error
  return { data }
}
