import Ajv, { JSONSchemaType, Schema } from 'ajv'
import addAjvFormats from 'ajv-formats'

export {
  default as Ajv,
  type SchemaObject,
  type JSONSchemaType,
} from 'ajv'
export { default as addAjvFormats } from 'ajv-formats'

const defaultAjv = new Ajv({
  allErrors: true,
  $data: true,
})
addAjvFormats(defaultAjv)

export function compileJsonSchema(
  schema: Schema | JSONSchemaType<unknown>
) {
  return defaultAjv.compile(schema)
}
