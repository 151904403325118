import {
  ContactRelationshipSchema,
  ContactSchema,
} from '@soniq/schema-connection'

export const CREATE_CONTACT_ID = 'create'

export type NewStudentContactSchema = Pick<
  ContactRelationshipSchema,
  'relationship'
> &
  Pick<ContactSchema, 'name' | 'email'>

export type ExistingStudentContactSchema = Omit<
  ContactRelationshipSchema,
  'subject_id'
> & {
  id?: string
}

export type NewContactOrExistingRelationship =
  | NewStudentContactSchema
  | ExistingStudentContactSchema

export interface ManageStudentAndTheirContactsSchema
  extends Pick<
    ContactSchema,
    'name' | 'email' | 'lesson_invoice_setting_id'
  > {
  contacts: Array<NewContactOrExistingRelationship>
}
