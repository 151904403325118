import { GENERIC_SCHEDULE_EVENT_TYPE } from './types'
import { CleanValuesOptions } from '@pogokid/schema'

export function cleanScheduleProperties(
  values: Record<any, any>
) {
  if (
    values &&
    'type' in values &&
    !('title' in values) &&
    values.type === GENERIC_SCHEDULE_EVENT_TYPE
  ) {
    return { type: GENERIC_SCHEDULE_EVENT_TYPE }
  }
  return values
}

export const cleanScheduleOptions: CleanValuesOptions = {
  properties: cleanScheduleProperties,
}
