import { useSetState } from '@pogokid/react-hooks'
import { SupabaseClient } from '@supabase/supabase-js'
import { getPublicConfig } from '~/config'
import { getInfoLogger } from '~/logger'
import { useRouterSearchParams } from '~/router'
import { accountSecurityPath } from '~m/account/paths'
import { signinPathWithReturnTo } from '~m/auth/paths'

const log = getInfoLogger('ui:sign-in-flows')

export type SignInFlowOptions =
  | { type: 'Google' }
  | { type: 'EmailLink'; email: string; returnUrl: string }
  | {
      type: 'EmailPassword'
      email: string
      password: string
      returnUrl: string
    }

export async function signIn(
  supabase: SupabaseClient,
  options: SignInFlowOptions
) {
  const { callbackBaseUrl } = getPublicConfig()
  switch (options.type) {
    case 'EmailLink':
      await supabase.auth.signInWithOtp({
        email: options.email,
        options: {
          emailRedirectTo: `${callbackBaseUrl}${signinPathWithReturnTo(
            options.returnUrl
          )}`,
        },
      })
      break
    case 'EmailPassword':
      const { error } = await supabase.auth.signInWithPassword({
        email: options.email,
        password: options.password,
      })
      if (error) {
        throw error
      }
      break
    // case 'Google':
    //   await signInWithPopup(auth, googleProvider)
    //   break
    default:
      throw new Error('Sign in flow type must be provided')
  }
}

export interface ConfirmEmailTokenOptions {
  email: string
  token: string
}

export async function confirmEmailToken(
  db: SupabaseClient,
  { email, token }: ConfirmEmailTokenOptions
) {
  const { error } = await db.auth.verifyOtp({
    type: 'email',
    email,
    token,
  })
  if (error) {
    throw error
  }
}

export interface ResetPasswordOptions {
  email: string
}

export async function sendResetPasswordForEmail(
  db: SupabaseClient,
  { email }: ResetPasswordOptions
) {
  const { callbackBaseUrl } = getPublicConfig()
  const { error } = await db.auth.resetPasswordForEmail(email, {
    redirectTo: `${callbackBaseUrl}${signinPathWithReturnTo(
      accountSecurityPath()
    )}`,
  })
  if (error) {
    throw error
  }
}

type SignInFlowTokenState =
  | { type: 'Unknown' }
  | {
      type: 'EmailLink'
      isMissingEmail: false
    }
  | {
      type: 'EmailLink'
      isMissingEmail: true
      setEmail: (email: string) => Promise<void>
    }

export function useSignInFlowToken() {
  const [state, setState] = useSetState<SignInFlowTokenState>({
    type: 'Unknown',
  })
  const query = useRouterSearchParams()
  const returnTo = query?.get('returnTo')
  return { state, returnTo }
}
