import {
  compileValidationSchema,
  makePropertyCleaner,
  schemaProperties,
} from '@pogokid/schema'
import { PaymentSchema } from '../../.types/Payment.schema'
import schema from './Payment.schema.json'
import { compileJsonSchema } from '@pogokid/schema/dynamic'

export const validatePayment =
  compileValidationSchema<PaymentSchema>(
    'PaymentSchema',
    compileJsonSchema(schema)
  )

export const paymentProperties = schemaProperties(schema)
export const cleanPaymentProperties =
  makePropertyCleaner<PaymentSchema>(paymentProperties)
