import { has, omit } from '@pogokid/util/lodash'
import { OmitCreated } from './types'
import { AnyRecord } from '@pogokid/util'

export function omitIdField<T extends object>(data: T) {
  return omit(['id'], data) as Omit<T, 'id'>
}

export type CreatedFields = {
  created_by: string | null
  created_at: string
}

export type DeletedFields = {
  deleted_by?: string | null
  deleted_at?: string | null
}

export function hasCreatedFields<T extends AnyRecord>(
  data: T
): data is T & CreatedFields {
  return has('created_by', data) && has('created_at', data)
}

export function omitCreatedFields<T extends object>(
  data: T
): OmitCreated<T> {
  return omit(
    ['created_by', 'created_at'],
    data
  ) as OmitCreated<T>
}

export function ensureCreatedFields<T extends CreatedFields>(
  userId: string,
  data: T | OmitCreated<T>
): T & CreatedFields {
  if (hasCreatedFields(data)) {
    return data as T & CreatedFields
  }
  const result = {
    ...data,
    created_by: userId,
    created_at: new Date().toISOString(),
  } as unknown
  return result as T
}

export function renewCreatedFields<T extends CreatedFields>(
  userId: string,
  data: T | OmitCreated<T>
): T & CreatedFields {
  return ensureCreatedFields<T>(
    userId,
    omitCreatedFields<T>(data as T)
  )
}
