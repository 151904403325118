import {
  useEnsureUserId,
  useSupabaseClient,
} from '@pogokid/supabase/react'
import { Optional } from '@pogokid/util'
import {
  useEntityById,
  useEntityMutations,
} from '@soniq/public-manager'
import {
  AsyncQueryResponse,
  AsyncSingleResponse,
  ResponseWithRefetch,
} from '@soniq/public-resource'
import {
  getContactById,
  getContactCollection,
  getContactsByIds,
} from '@soniq/public-resource-connection'
import { SchemaWithId } from '@soniq/schema'
import {
  ContactSchema,
  ContactWithId,
} from '@soniq/schema-connection'
import { TaggedEntitySchema } from '@soniq/schema-util'
import { useQuery } from '@tanstack/react-query'
import { useCallback } from 'react'
import { contactKeys, taggedEntityKeys } from '../keys'

export function useContacts(
  limitResults = 200,
  listen = false
): AsyncQueryResponse<ContactWithId> {
  const userId = useEnsureUserId()
  const db = useSupabaseClient()
  const { data, isLoading, error } = useQuery({
    enabled: !!userId,
    queryKey: contactKeys.byUserId(userId),
    queryFn: async () =>
      userId
        ? await getContactCollection(db, userId, limitResults)
        : null,
  })
  return {
    value: data?.data,
    loading: isLoading,
    error: error as Error | null | undefined,
  }
}

export function useContactById(id: Optional<string>) {
  const db = useSupabaseClient()
  return useEntityById(id, contactKeys.detail(id), contactKeys, {
    queryFn: async () =>
      id ? await getContactById(db, id) : null,
  })
}

export function useContactsByIds(
  ids: Optional<string[]>
): AsyncSingleResponse<ContactWithId[]> & ResponseWithRefetch {
  const db = useSupabaseClient()
  const enabled = Array.isArray(ids) && ids.length > 0
  const { data, isLoading, error, refetch } = useQuery({
    enabled,
    queryKey: contactKeys.list({ byIds: ids?.join() }),
    queryFn: async () =>
      Array.isArray(ids) && ids.length > 0
        ? await getContactsByIds(db, ids)
        : null,
  })
  return {
    value: data?.data,
    loading: isLoading,
    error: error as Error | null | undefined,
    refetch,
  }
}

export function useMutateContact() {
  const userId = useEnsureUserId()
  const { update } = useEntityMutations<
    ContactSchema,
    typeof contactKeys
  >({
    entityKeys: contactKeys,
    userId,
  })
  return {
    updateContact: useCallback(
      async function updateContact(
        contact: SchemaWithId<Partial<ContactSchema>>
      ) {
        const { data } = await update(contact)
        return data
      },
      [update]
    ),
  }
}

export function useMutateTaggedEntity() {
  const userId = useEnsureUserId()
  return useEntityMutations<
    TaggedEntitySchema,
    typeof taggedEntityKeys
  >({
    entityKeys: taggedEntityKeys,
    userId,
  })
}
