import { Optional } from '@pogokid/util'
import {
  formatUtcIsoDate,
  IntervalISOString,
} from '@pogokid/util/datetime'
import { buildEntityKeys } from '@soniq/public-manager'

export const scheduleKeys = buildEntityKeys<{
  studentId: Optional<string>
  start: Optional<string>
  studentUnavailable: boolean
}>('schedule')

export const scheduleInstanceKeys = {
  ...buildEntityKeys<{ scheduleId: string }>(
    'schedule_instance'
  ),
  byStudentAndInterval: (
    studentId: Optional<string>,
    interval: IntervalISOString
  ) =>
    [
      ...scheduleInstanceKeys.lists(),
      'by-student-and-interval',
      {
        studentId,
        start: interval.start,
        end: interval.end,
      },
    ] as const,
  ensure: (
    scheduleId: Optional<string>,
    eventStart: Optional<Date>
  ) =>
    [
      ...scheduleInstanceKeys.all,
      'ensure',
      {
        scheduleId,
        eventStart: eventStart
          ? formatUtcIsoDate(eventStart)
          : null,
      },
    ] as const,
}
