import { getVerboseLogger } from '@pogokid/log'
import { SupabaseClient } from '@pogokid/supabase'
import { startOfMinute } from '@pogokid/util/datetime'
import {
  QueryResponse,
  SingleResponse,
  UpsertSchema,
} from '@soniq/public-resource'
import {
  updateMany,
  updateOne,
  upsertManyInto,
} from '@soniq/public-resource/supabase'
import {
  isSchemaWithId,
  OmitCreated,
  SchemaWithId,
} from '@soniq/schema'
import {
  isScheduleInstance,
  ScheduleInstanceSchema,
  ScheduleInstanceWithId,
  validateScheduleInstance,
} from '@soniq/schema-scheduling'
import { Database } from '@soniq/util-supabase'

const verbose = getVerboseLogger('ui:schedule_instance:store')

export const scheduleInstanceCollection = (db: SupabaseClient) =>
  (db as SupabaseClient<Database>).from('schedule_instance')

export async function scheduleInstanceById(
  db: SupabaseClient,
  scheduleInstanceId: string
): Promise<SingleResponse<ScheduleInstanceWithId>> {
  const { data, error } = await scheduleInstanceCollection(db)
    .select<'*', ScheduleInstanceWithId>()
    .eq('id', scheduleInstanceId)
    .single()
  if (error) throw error
  return { data }
}

export async function scheduleInstanceByIds(
  db: SupabaseClient,
  scheduleInstanceIds: string[]
): Promise<QueryResponse<ScheduleInstanceWithId>> {
  const { data, error } = await scheduleInstanceCollection(db)
    .select<'*', ScheduleInstanceWithId>()
    .in('id', scheduleInstanceIds)
  if (error) throw error
  return { data }
}

export async function ensureScheduleInstance(
  db: SupabaseClient<Database>,
  scheduleId: string,
  eventStart: Date
): Promise<SingleResponse<ScheduleInstanceWithId>> {
  const { data, error } = await db
    .rpc('ensure_schedule_instance', {
      schedule_id: scheduleId,
      event_date: startOfMinute(eventStart).toISOString(),
    })
    .single()
  if (error) throw error
  if (isSchemaWithId(data) && isScheduleInstance(data)) {
    return { data: data }
  } else {
    return { data: null }
  }
}

export async function createScheduleInstance(
  db: SupabaseClient,
  userId: string,
  scheduleInstance: OmitCreated<ScheduleInstanceSchema>
): Promise<SingleResponse<ScheduleInstanceWithId>> {
  const data = {
    ...scheduleInstance,
    created_by: userId,
    created_at: new Date().toISOString(),
    event_start: startOfMinute(
      new Date(scheduleInstance.event_start)
    ).toISOString(),
  }
  const { isValid, cleanedValues, errors } =
    validateScheduleInstance(data)
  if (isValid) {
    const { data, error } = await scheduleInstanceCollection(db)
      .insert<ScheduleInstanceSchema>(cleanedValues)
      .select()
      .single()
    if (error) throw error
    return { data: data as ScheduleInstanceWithId }
  } else {
    verbose('Invalid data', errors)
    throw new Error(
      'Cannot create schedule_instance, invalid data'
    )
  }
}

export async function updateScheduleInstance(
  db: SupabaseClient,
  id: string,
  {
    created_by,
    created_at,
    ...scheduleInstance
  }: Partial<ScheduleInstanceSchema>
) {
  return updateOne<ScheduleInstanceWithId>(
    scheduleInstanceCollection(db),
    id,
    scheduleInstance
  )
}

export async function updateScheduleInstances(
  db: SupabaseClient,
  instances: Array<SchemaWithId<Partial<ScheduleInstanceSchema>>>
) {
  return updateMany<ScheduleInstanceSchema>(
    scheduleInstanceCollection(db),
    instances
  )
}

export async function setScheduleInstances(
  db: SupabaseClient,
  scheduleInstances: UpsertSchema<ScheduleInstanceWithId>[]
) {
  return await upsertManyInto<ScheduleInstanceSchema>(
    scheduleInstanceCollection(db),
    scheduleInstances,
    validateScheduleInstance
  )
}
