import {
  isEmpty,
  isFunction,
  isPlainObject,
  pick,
  toPlainObject,
} from '@pogokid/util/lodash'
import { isArrayWithLength } from '@pogokid/util'
import { type SchemaObject } from 'ajv'

export interface CleanValuesOptions {
  /**
   * Convert each property to a JS primitive
   */
  toPrimitive?: boolean
  /**
   * Optional predicate for each property to control whether
   * it should be cleaned
   */
  shouldCleanProperties?: (
    propertyName: string,
    value: any
  ) => boolean
  /**
   * Pre-process object before it's properties
   * are cleaned
   *
   * @example
   * ```ts
   * properties: function(
   *   values: Record<any, any>
   * ) {
   *   if (
   *     values &&
   *     'type' in values &&
   *     !('title' in values) &&
   *     values.type === GENERIC_SCHEDULE_EVENT_TYPE
   *   ) {
   *     return { type: GENERIC_SCHEDULE_EVENT_TYPE }
   *   }
   *   return values
   * }
   * ```
   */
  properties?:
    | (<T extends Record<any, any>>(
        values: T,
        options: CleanValuesOptions
      ) => T)
    /** @deprecated */
    | string[]
    | null
}

export function schemaProperties(schema: SchemaObject) {
  return !isEmpty(schema.properties)
    ? Object.keys(schema.properties)
    : []
}

/**
 * Make sure an object only includes properties
 * on the original schema
 */
export function makePropertyCleaner<T>(properties: string[]) {
  return (values: T): T => pick(properties, values) as T
}

export function cleanValues<T extends Record<any, any>>(
  values: T,
  options: CleanValuesOptions = {}
): T {
  return cleanValue(values, options) as T
}

export function cleanValue<T>(
  values: T,
  options: CleanValuesOptions = {}
): T | null {
  const { toPrimitive, properties } = options
  let cleanedValue: any = values
  if (Array.isArray(cleanedValue)) {
    cleanedValue = cleanedValue.map((item) =>
      cleanValue(item, options)
    )
  } else if (typeof cleanedValue === 'string') {
    cleanedValue = String(cleanedValue).trim()
  } else if (typeof cleanedValue === 'number') {
    return cleanedValue as any
  } else if (typeof cleanedValue === 'boolean') {
    return cleanedValue as any
  } else if (cleanedValue && typeof cleanedValue === 'object') {
    cleanedValue = isFunction(properties)
      ? properties(cleanedValue, options)
      : isArrayWithLength(properties)
      ? pick(properties, cleanedValue)
      : cleanedValue
    cleanedValue = isPlainObject(cleanedValue)
      ? Object.fromEntries(
          Object.entries(cleanedValue).map((entry) => {
            const [key, v] = entry
            if (
              options.shouldCleanProperties &&
              !options.shouldCleanProperties(key, v)
            ) {
              return entry
            }
            return [
              key,
              cleanValue(v, {
                ...options,
                properties: isFunction(properties)
                  ? properties
                  : null,
              }),
            ]
          })
        )
      : cleanedValue
    if (toPrimitive) cleanedValue = toPlainObject(cleanedValue)
  }
  if (isEmpty(cleanedValue) && !Array.isArray(cleanedValue)) {
    return null
  }
  return cleanedValue
}
