import { useSupabaseClient } from '@pogokid/supabase/react'
import { DURATION_FIVE_MINUTES } from '@pogokid/util/datetime'
import { getIntegrationToken } from '@soniq/public-resource-integrate'
import { useQuery } from '@tanstack/react-query'
import { integrationTokenKeys } from '../keys'
import { IntegrationId, PublicIntegrationToken } from '../types'
import { useIntegrateManager } from '../IntegrateManagerProvider.tsx'
import { useConnectionManager } from '@soniq/public-manager-connection'

export const useIntegrationToken = (
  handle: IntegrationId,
  profileId?: string,
  refetchOnMount?: boolean
): PublicIntegrationToken & { refetch: () => void } => {
  const { apiBaseUrl } = useIntegrateManager()
  const db = useSupabaseClient()
  const { profileId: defaultProfileId } = useConnectionManager()
  const integrationProfileId = profileId ?? defaultProfileId
  const { data, error, isLoading, refetch, dataUpdatedAt } =
    useQuery({
      enabled: !!handle && !!integrationProfileId,
      queryKey: integrationTokenKeys.detail(handle),
      queryFn: () =>
        getIntegrationToken(
          apiBaseUrl,
          db,
          handle,
          integrationProfileId ?? ''
        ),
      gcTime: DURATION_FIVE_MINUTES * 2,
      staleTime: DURATION_FIVE_MINUTES,
      refetchOnMount: refetchOnMount ? 'always' : undefined,
    })
  return {
    loading: isLoading,
    error,
    refetch: async () => {
      await refetch()
    },
    exists: data?.data?.exists,
    accessToken: data?.data?.access_token,
    expiresAt: data?.data?.expires_at,
  }
}
