import {
  compileValidationSchema,
  makePropertyCleaner,
  schemaProperties,
} from '@pogokid/schema'
import { PriceSchema } from '../../.types/Price.schema'
import { cleanPriceOptions } from './clean'
import priceSchema from './Price.schema.json'
import { compileJsonSchema } from '@pogokid/schema/dynamic'

export const validatePrice =
  compileValidationSchema<PriceSchema>(
    'PriceSchema',
    compileJsonSchema(priceSchema),
    cleanPriceOptions
  )

export const priceProperties = schemaProperties(priceSchema)

export const cleanPriceProperties =
  makePropertyCleaner<PriceSchema>(priceProperties)
