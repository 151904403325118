import { SupabaseReadWriteStore } from '@soniq/public-resource/supabase'
import {
  IntegrationConnectionSchema,
  validateIntegrationConnection,
} from '@soniq/schema-integrate'

export class IntegrationConnectionStore extends SupabaseReadWriteStore<
  'integration_connection',
  IntegrationConnectionSchema
> {
  static new() {
    return new IntegrationConnectionStore(
      'integration_connection',
      validateIntegrationConnection
    )
  }
}
