import { lazy, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { AppLoadingOrError } from '~m/AppUi/components/AppLoadingOrError.tsx'

const HomePage = lazy(async () => import('./pages/index'))

const HandlePage = lazy(
  () => import('./pages/[handle]/index.tsx')
)
const HandleContactsPage = lazy(
  () => import('./pages/[handle]/contacts')
)
const HandleContactIdPage = lazy(
  () => import('./pages/[handle]/contacts/[id]/index.tsx')
)
const HandleInvoiceIdPage = lazy(
  () => import('./pages/[handle]/invoice/[id]/index.tsx')
)
const HandleInvoicingPage = lazy(
  () => import('./pages/[handle]/invoicing')
)
const HandleInvoicingGeneratePage = lazy(
  () => import('./pages/[handle]/invoicing/generate')
)
const HandleSchedulePage = lazy(
  () => import('./pages/[handle]/schedule')
)
const HandleScheduleWeekPage = lazy(
  () => import('./pages/[handle]/schedule/week')
)
const HandleScheduleWeekRangePage = lazy(
  () =>
    import('./pages/[handle]/schedule/week/[range]/index.tsx')
)

// ----- Account
const AccountIndex = lazy(() => import('./pages/account'))
const AccountBilling = lazy(
  () => import('./pages/account/billing')
)
const AccountEmails = lazy(
  () => import('./pages/account/emails')
)
const AccountIntegrations = lazy(
  () => import('./pages/account/integrations')
)
const AccountPayments = lazy(
  () => import('./pages/account/payments')
)
const AccountPaymentsOnboarding = lazy(
  () => import('./pages/account/payments/onboarding')
)
const AccountSecurity = lazy(
  () => import('./pages/account/security')
)

// -----
const DashboardPage = lazy(() => import('./pages/dashboard'))
const GetStartedPage = lazy(() => import('./pages/getstarted'))
const IntegrationPage = lazy(
  () => import('./pages/integration/[handle]/index.tsx')
)
const InvitePage = lazy(
  () => import('./pages/invite/[id]/index.tsx')
)
const PayPage = lazy(
  () => import('./pages/pay/[link]/index.tsx')
)
const PricingPage = lazy(() => import('./pages/pricing'))
const SignInPage = lazy(() => import('./pages/signin'))
const SubscribePage = lazy(() => import('./pages/subscribe'))

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<AppLoadingOrError />}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route
            path="/getstarted"
            element={<GetStartedPage />}
          />
          <Route
            path="/integration/:handle"
            element={<IntegrationPage />}
          />
          <Route path="/invite/:id" element={<InvitePage />} />
          <Route path="/pay/:link" element={<PayPage />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/signin" element={<SignInPage />} />
          <Route path="/subscribe" element={<SubscribePage />} />
          <Route path="/account/*">
            <Route index element={<AccountIndex />} />
            <Route path="billing" element={<AccountBilling />} />
            <Route path="emails" element={<AccountEmails />} />
            <Route
              path="integrations"
              element={<AccountIntegrations />}
            />
            <Route path="payments">
              <Route index element={<AccountPayments />} />
              <Route
                path="onboarding"
                element={<AccountPaymentsOnboarding />}
              />
            </Route>
            <Route
              path="security"
              element={<AccountSecurity />}
            />
          </Route>
          <Route path="/:handle/*">
            <Route index element={<HandlePage />} />
            <Route path="contacts">
              <Route index element={<HandleContactsPage />} />
              <Route
                path=":id"
                element={<HandleContactIdPage />}
              />
            </Route>
            <Route path="invoice">
              <Route
                path=":id"
                element={<HandleInvoiceIdPage />}
              />
            </Route>
            <Route path="invoicing">
              <Route index element={<HandleInvoicingPage />} />
              <Route
                path="generate"
                element={<HandleInvoicingGeneratePage />}
              />
            </Route>
            <Route path="schedule">
              <Route index element={<HandleSchedulePage />} />
              <Route path="week">
                <Route
                  index
                  element={<HandleScheduleWeekPage />}
                />
                <Route
                  path=":range"
                  element={<HandleScheduleWeekRangePage />}
                />
              </Route>
            </Route>
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}

export default App
