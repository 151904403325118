import {
  compileValidationSchema,
  makePropertyCleaner,
  schemaProperties,
} from '@pogokid/schema'
import { ContactSchema } from '../../.types/Contact.schema'
import contactSchema from '../contact/Contact.schema.json'
import { compileJsonSchema } from '@pogokid/schema/dynamic'

export const validateContact =
  compileValidationSchema<ContactSchema>(
    'ContactSchema',
    compileJsonSchema(contactSchema),
    {
      // Only clean meta if there are values
      shouldCleanProperties(property, value) {
        return (
          property !== 'meta' || Object.keys(value).length > 0
        )
      },
    }
  )
export const contactProperties = schemaProperties(contactSchema)
export const cleanContactProperties =
  makePropertyCleaner<ContactSchema>(contactProperties)
