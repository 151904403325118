import { SupabaseClient } from '@pogokid/supabase'
import {
  QueryResponse,
  SingleResponse,
} from '@soniq/public-resource'
import {
  deletePermanentMany,
  insertInto,
  updateOne,
  upsertManyInto,
} from '@soniq/public-resource/supabase'
import { OmitCreated, renewCreatedFields } from '@soniq/schema'
import {
  ContactRelationshipSchema,
  ContactRelationshipWithId,
  validateContactRelationship,
} from '@soniq/schema-connection'
import { Database } from '@soniq/util-supabase'

export const contactRelationshipCollection = (
  db: SupabaseClient
) =>
  (db as SupabaseClient<Database>).from('contact_relationship')

export async function getContactRelationshipById(
  db: SupabaseClient,
  studentContactId: string
): Promise<SingleResponse<ContactRelationshipWithId>> {
  const { data, error } = await contactRelationshipCollection(db)
    .select()
    .eq('id', studentContactId)
    .single<ContactRelationshipWithId>()
  if (error) throw error
  return { data }
}

export async function createContactRelationship(
  db: SupabaseClient,
  userId: string,
  contactRelationship: OmitCreated<ContactRelationshipSchema>
): Promise<SingleResponse<ContactRelationshipWithId>> {
  const newData = renewCreatedFields<ContactRelationshipSchema>(
    userId,
    contactRelationship
  )
  if (!validateContactRelationship(newData).isValid) {
    throw new Error(
      'Cannot create contact relationship, invalid data'
    )
  }
  return insertInto<ContactRelationshipSchema>(
    contactRelationshipCollection(db),
    newData
  )
}

export async function setContactRelationships(
  db: SupabaseClient,
  contactRelationsips: ContactRelationshipWithId[]
): Promise<QueryResponse<ContactRelationshipWithId>> {
  const invalid = contactRelationsips.find(
    ({ id, ...relationship }) =>
      !validateContactRelationship(relationship).isValid
  )
  if (invalid) {
    throw new Error(`Cannot set contacts, invalid data`)
  }
  return upsertManyInto<ContactRelationshipSchema>(
    contactRelationshipCollection(db),
    contactRelationsips
  )
}

export async function deleteContactRelationships(
  db: SupabaseClient,
  ids: string[]
) {
  return deletePermanentMany(
    contactRelationshipCollection(db),
    ids
  )
}

export async function updateContactRelationship(
  db: SupabaseClient,
  id: string,
  contactRelationship: Partial<ContactRelationshipSchema>
): Promise<SingleResponse<ContactRelationshipWithId>> {
  return await updateOne<ContactRelationshipSchema>(
    contactRelationshipCollection(db),
    id,
    contactRelationship
  )
}
