import {
  useNavigate,
  useLocation,
  useSearchParams,
  useParams,
} from 'react-router-dom'
import { useCallback } from 'react'

export function useRouterLocation() {
  const { pathname } = useLocation()
  return {
    pathname,
  }
}

export function useRouterSearchParams() {
  const [searchParams] = useSearchParams()
  return searchParams
}

export function useRouterParameters<
  T extends Record<string, string | undefined>
>() {
  return useParams<T>()
}

export function useRouterNavigation() {
  // const { push, back, forward, replace, reload } = useRouter()
  const navigate = useNavigate()
  return {
    push: navigate,
    replace: useCallback(
      (path: string) => navigate(path, { replace: true }),
      [navigate]
    ),
    // back,
    // forward,
    // reload,
  }
}
