import {
  SnackbarActionEvent,
  SnackbarItemAction,
  SnackbarItemOptions,
} from './types'
import { useSnackbarContext } from './SnackbarProvider'
import { useEffectOnce } from '@pogokid/react-hooks'
import { useCallback, useEffect } from 'react'

export function useSnack(snack: SnackbarItemOptions) {
  const { addSnack } = useSnackbarContext()
  useEffectOnce(() => {
    addSnack(snack)
  })
  return { snackId: snack.id }
}

export function useSnackAction(
  snackId: string,
  action: SnackbarItemAction,
  onAction: (action: SnackbarItemAction) => void
) {
  const { addSnackAction, addOnAction, removeOnAction } =
    useSnackbarContext()
  useEffectOnce(() => {
    addSnackAction(snackId, action)
  })
  const handleAction = useCallback(
    (ev: CustomEvent<SnackbarActionEvent>) => {
      if (
        ev.detail.snackId === snackId &&
        ev.detail.action.id === action.id
      ) {
        onAction(ev.detail.action)
      }
    },
    [action.id, onAction, snackId]
  )
  useEffect(() => {
    addOnAction(handleAction)
    return () => {
      removeOnAction(handleAction)
    }
  }, [addOnAction, handleAction, removeOnAction])
}
