import { ValidateSchemaOptions } from '@pogokid/schema'
import { newId, SupabaseClient } from '@pogokid/supabase'
import {
  useEnsureUserId,
  useSupabaseClient,
} from '@pogokid/supabase/react'
import { isArrayWithLength, Optional } from '@pogokid/util'
import { omit } from '@pogokid/util/lodash'
import {
  AsyncQueryResponse,
  AsyncSingleResponse,
  QueryResponse,
  ResponseWithRefetch,
  SingleResponse,
  UpsertSchema,
} from '@soniq/public-resource'
import {
  SupabaseReadStoreOf,
  SupabaseReadWriteStore,
} from '@soniq/public-resource/supabase'
import {
  CreatedFields,
  isSchemaWithId,
  SchemaWithId,
} from '@soniq/schema'
import {
  MutationObserverOptions,
  QueryClient,
  QueryKey,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query'
import { useCallback } from 'react'
import { findEntityInitialData } from './entityInvalidation'
import { EntityKeys, EntityMutationParams } from './types'

export const buildEntityKeys = <
  Filters extends Record<string, any> = {}
>(
  allKey: string
): EntityKeys<Filters> => {
  const all = [allKey] as const
  const lists = () => [...all, 'list'] as const
  const details = () => [...all, 'detail'] as const
  const mutation = () => [...all, 'mutation'] as const
  return {
    all,
    lists,
    details,
    list: (filters: Partial<Filters> = {}) =>
      [...lists(), filters] as const,
    detail: (id: Optional<string>) =>
      [...details(), id] as const,
    mutation,
  }
}

export function buildEntityReadHooks<
  Schema extends CreatedFields,
  Keys extends EntityKeys<Record<string, unknown>>,
  Store extends SupabaseReadStoreOf<Schema>
>(entityKeys: Keys, store: Store) {
  return {
    useById(id: Optional<string>) {
      const db = useSupabaseClient()
      return useEntityById<SchemaWithId<Schema>, Keys>(
        id,
        entityKeys.detail(id),
        entityKeys,
        {
          queryFn: () => store.fetchById(db, id!),
        }
      )
    },
    // useByIds(ids: string[]) {
    //   const db = useSupabaseClient()
    //   return
    // },
    useByQuery(filters: any) {
      const db = useSupabaseClient()
      return useEntitiesList(entityKeys.list(filters), {
        queryFn: () => store.fetchQuery(db, filters),
      })
    },
  }
}

export function buildEntityHooks<
  Schema extends CreatedFields,
  Keys extends EntityKeys<Record<string, any>>,
  Store extends SupabaseReadWriteStore<any, Schema>
>(entityKeys: Keys, store: Store) {
  return {
    useValidate() {
      const validate = useCallback(
        (
          data: Schema & { id?: string },
          options?: ValidateSchemaOptions
        ) => {
          return store.validate(
            omit('id', data) as Schema,
            options
          )
        },
        []
      )
      const validateForm = useCallback(
        (
          data: Schema & { id?: string },
          options?: ValidateSchemaOptions
        ) => validate(data, options).errors,
        [validate]
      )
      return { validate, validateForm }
    },
    useById(id: Optional<string>) {
      const db = useSupabaseClient()
      return useEntityById<SchemaWithId<Schema>, Keys>(
        id,
        entityKeys.detail(id),
        entityKeys,
        {
          queryFn: () => store.fetchById(db, id!),
        }
      )
    },
    // useByIds(ids: string[]) {
    //   const db = useSupabaseClient()
    //   return
    // },
    useMutation() {
      const userId = useEnsureUserId()
      return useEntityMutations<Schema, Keys>({
        entityKeys,
        userId,
      })
    },
  }
}

export function useEntitiesList<
  Schema extends SchemaWithId<unknown>
>(
  listKey: QueryKey,
  options: Omit<
    UseQueryOptions<
      QueryResponse<Schema> | null,
      Error,
      QueryResponse<Schema>
    >,
    'queryKey'
  >
): AsyncQueryResponse<Schema> & ResponseWithRefetch {
  const { data, isLoading, error, refetch } = useQuery({
    ...options,
    enabled: options.enabled !== false,
    queryKey: listKey,
  })
  return {
    value: data?.data,
    loading: isLoading,
    error: error as Error | null | undefined,
    refetch,
  }
}

export function useEntityById<
  Schema extends SchemaWithId<unknown>,
  Keys extends EntityKeys<Record<string, any>>
>(
  id: Optional<string>,
  queryKey: QueryKey,
  entityKeys: Keys,
  options: {
    idProp?: keyof Schema
  } & Omit<
    UseQueryOptions<
      SingleResponse<Schema> | null,
      Error,
      SingleResponse<Schema>
    >,
    'queryKey'
  >
): AsyncSingleResponse<Schema> & ResponseWithRefetch {
  const queryClient = useQueryClient()
  const { idProp, ...queryOptions } = options
  const { data, isLoading, error, refetch } = useQuery({
    ...queryOptions,
    enabled: options.enabled !== false && !!id,
    queryKey: queryKey,
    ...findEntityInitialData<Schema, Keys>({
      queryClient,
      entityKeys,
      id,
      idProp,
    }),
  })
  return {
    value: data?.data,
    loading: isLoading,
    error: error as Error | null | undefined,
    refetch,
  }
}

export function setEntityStoreMutations<
  Schema extends CreatedFields,
  Keys extends EntityKeys<Record<string, any>>,
  Store extends SupabaseReadWriteStore<any, Schema>
>({
  queryClient,
  entityKeys,
  store,
  db,
}: {
  queryClient: QueryClient
  entityKeys: Keys
  store: Store
  db: SupabaseClient
}) {
  setEntityMutations<Schema, Keys>({
    queryClient,
    entityKeys,
    upsertEntities: async ({ data }) => store.upsert(db, data),
    updateEntity: async ({ data }) => store.update(db, data),
    deleteEntity: async () => {
      throw new Error('Delete not allowed')
    },
  })
}

/**
 * Default mutations for an entity so that paused
 * mutations can resume after a page reload
 */
export function setEntityMutations<
  Schema extends Record<any, any>,
  Keys extends EntityKeys<Record<string, any>>
>({
  queryClient,
  entityKeys,
  upsertEntities,
  updateEntity,
  deleteEntity,
  actionEntity,
}: {
  queryClient: QueryClient
  entityKeys: Keys
  upsertEntities: (
    data: EntityMutationParams<UpsertSchema<Schema>[]>
  ) => Promise<QueryResponse<SchemaWithId<Schema>>>
  updateEntity: (
    data: EntityMutationParams<SchemaWithId<Schema>>
  ) => Promise<SingleResponse<SchemaWithId<Schema>>>
  deleteEntity: (
    data: EntityMutationParams<null>
  ) => Promise<void>
  actionEntity?: (
    id: string,
    action: unknown
  ) => Promise<SingleResponse<SchemaWithId<any>>>
}) {
  if (
    queryClient.getMutationDefaults(entityKeys.mutation())
      .onMutate
  ) {
    return
  }
  const options: Omit<
    MutationObserverOptions<
      EntityMutationParams<
        | SingleResponse<SchemaWithId<Schema>>
        | QueryResponse<SchemaWithId<Schema>>
        | null
      >,
      any,
      EntityMutationParams,
      void
    >,
    'mutationKey'
  > = {
    mutationFn: async (params) => {
      const emptyResult: EntityMutationParams<null> = {
        ...params,
        data: null,
      }
      switch (params.operation) {
        case 'upsert':
          if (
            isArrayWithLength(params.data) &&
            isSchemaWithId(params.data[0])
          ) {
            return {
              ...params,
              data: await upsertEntities(
                params as EntityMutationParams<
                  SchemaWithId<Schema>[]
                >
              ),
            }
          }
          break
        case 'update':
          if (isSchemaWithId(params.data)) {
            return {
              ...params,
              data: await updateEntity(
                params as EntityMutationParams<
                  SchemaWithId<Schema>
                >
              ),
            }
          }
          break
        case 'delete':
          await deleteEntity(
            params as EntityMutationParams<null>
          )
          return emptyResult
        case 'action':
          if (actionEntity) {
            return {
              ...params,
              data: await actionEntity(params.id!, params.data),
            }
          }
          break
        default:
          return emptyResult
      }
      return emptyResult
    },
    onMutate: async ({ id }) => {
      // cancel any queries for the id that is being mutated
      await queryClient.cancelQueries({
        queryKey: entityKeys.detail(id),
      })
      // TODO: DT 2024-02-04 create an optimistic entity
    },
    async onSuccess({ id, operation, data }) {
      switch (operation) {
        case 'upsert':
          if (isArrayWithLength(data?.data)) {
            await queryClient.invalidateQueries({
              queryKey: entityKeys.lists(),
            })
            await Promise.all(
              data.data.map((entity) =>
                queryClient.setQueryData(
                  entityKeys.detail(entity.id),
                  { data: entity }
                )
              )
            )
          }
          break
        case 'update':
          if (
            !Array.isArray(data?.data) &&
            isSchemaWithId(data?.data)
          ) {
            await queryClient.invalidateQueries({
              queryKey: entityKeys.lists(),
            })
            await queryClient.setQueryData(
              entityKeys.detail(data.data.id),
              data
            )
          }
          break
        case 'delete':
          queryClient.removeQueries({
            queryKey: entityKeys.detail(id),
          })
          break
      }
    },
  }
  queryClient.setMutationDefaults(entityKeys.mutation(), options)
}

/**
 * Mutations for an entity,
 * this also invalidates any cached
 * queries for the entity
 */
export function useEntityMutations<
  Schema extends Record<any, any>,
  Keys extends EntityKeys<Record<string, any>>,
  Actions = unknown
>(
  options: {
    entityKeys: Keys
    userId: string
  } & Pick<
    UseMutationOptions<
      SingleResponse<SchemaWithId<Schema>>,
      Error,
      Schema,
      {}
    >,
    'gcTime' | 'retry' | 'retryDelay' | 'networkMode'
  >
) {
  const { entityKeys, userId, ...queryOptions } = options
  const { mutate, mutateAsync, ...mutation } = useMutation<
    EntityMutationParams,
    Error,
    EntityMutationParams,
    {}
  >({
    ...queryOptions,
    mutationKey: entityKeys.mutation(),
  })

  return {
    ...mutation,
    upsert: useCallback(
      async (
        data: UpsertSchema<Schema>[]
      ): Promise<QueryResponse<SchemaWithId<Schema>>> => {
        const res = await mutateAsync(
          {
            id: null,
            operation: 'upsert',
            userId,
            data,
          },
          {}
        )
        return res.data as QueryResponse<SchemaWithId<Schema>>
      },
      [mutateAsync, userId]
    ),
    update: useCallback(
      (data: SchemaWithId<Partial<Schema>>) => {
        return mutateAsync({
          id: data.id,
          operation: 'update',
          userId,
          data,
        })
      },
      [mutateAsync, userId]
    ),
    action: useCallback(
      (data: Actions, ids?: string[]) => {
        return mutateAsync({
          id: newId(),
          operation: 'action',
          userId,
          data,
        })
      },
      [mutateAsync, userId]
    ),
  }
}
