import { forwardRef } from 'react'
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from '@pogokid/mui'

declare module '@mui/material/Button/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true
  }
}

interface ButtonProps extends MuiButtonProps {}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    return (
      <MuiButton
        ref={ref}
        {...props}
        color={props.color ?? 'neutral'}
      />
    )
  }
)
Button.displayName = 'Button'
