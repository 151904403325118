import { Button } from '~m/designSystem'
import SearchIcon from '@mui/icons-material/Search'
import React from 'react'
import { useAppUiContext } from '~m/AppUi/context/AppUiProvider.tsx'

export function QuickSearchButton() {
  const { showSearch } = useAppUiContext()
  return (
    <Button
      variant="outlined"
      startIcon={<SearchIcon />}
      onClick={() => {
        showSearch(true)
      }}
    >
      Search
    </Button>
  )
}
