import debug from 'debug'

const LOG_PREFIX = 'soniq'

export function getInfoLogger(name: string) {
  return debug(`${LOG_PREFIX}:info:${name}`)
}

export function getErrorLogger(name: string) {
  return debug(`${LOG_PREFIX}:error:${name}`)
}

export function getVerboseLogger(name: string) {
  return debug(`${LOG_PREFIX}:verbose:${name}`)
}

export function getAllLogger(name: string) {
  return {
    info: getInfoLogger(name),
    error: getErrorLogger(name),
    verbose: getVerboseLogger(name),
  }
}

export function enableLogger(verbose?: boolean) {
  if (verbose) {
    debug.enable(
      [
        `${LOG_PREFIX}:*`,
        `-${LOG_PREFIX}:trace:*`,
        process.env.DEBUG,
      ].join(',')
    )
  } else {
    debug.enable(
      [
        `${LOG_PREFIX}:*`,
        `-${LOG_PREFIX}:verbose:*`,
        `-${LOG_PREFIX}:trace:*`,
        process.env.DEBUG,
      ].join(',')
    )
  }
}
