import { SupabaseClient } from '@pogokid/supabase'
import { setEntityMutations } from '@soniq/public-manager'
import {
  scheduleStore,
  setScheduleInstances,
  updateScheduleInstance,
} from '@soniq/public-resource-scheduling'

import {
  ScheduleInstanceSchema,
  ScheduleSchema,
} from '@soniq/schema-scheduling'
import { QueryClient } from '@tanstack/react-query'
import { scheduleInstanceKeys, scheduleKeys } from './keys'

export function setSchedulingManagerEntityMutations(
  queryClient: QueryClient,
  db: SupabaseClient
) {
  // Schedule
  setEntityMutations<ScheduleSchema, typeof scheduleKeys>({
    queryClient,
    entityKeys: scheduleKeys,
    upsertEntities: async ({ data }) => {
      const res = await scheduleStore.upsert(db, data)
      await queryClient.invalidateQueries({
        queryKey: scheduleKeys.lists(),
      })
      return res
    },
    updateEntity: async ({ data }) => {
      const res = await scheduleStore.update(db, data)
      await queryClient.invalidateQueries({
        queryKey: scheduleKeys.lists(),
      })
      return res
    },
    deleteEntity: async ({ id }) => {
      if (id) {
        await scheduleStore.delete(db, [id])
        await queryClient.invalidateQueries({
          queryKey: scheduleKeys.lists(),
        })
      } else {
        throw new Error('Cannot delete schedule with no id')
      }
    },
  })
  // Schedule Instance
  setEntityMutations<
    ScheduleInstanceSchema,
    typeof scheduleInstanceKeys
  >({
    queryClient,
    entityKeys: scheduleInstanceKeys,
    upsertEntities: async ({ data }) => {
      const res = await setScheduleInstances(db, data)
      await Promise.all(
        res.data.map((instance) =>
          queryClient.setQueryData(
            scheduleInstanceKeys.ensure(
              instance.schedule_id,
              new Date(instance.event_start)
            ),
            { data: instance }
          )
        )
      )
      return res
    },
    updateEntity: async ({ data }) => {
      const res = await updateScheduleInstance(db, data.id, data)
      if (res.data) {
        queryClient.setQueryData(
          scheduleInstanceKeys.ensure(
            res.data.schedule_id,
            new Date(res.data.event_start)
          ),
          res
        )
      }
      return res
    },
    deleteEntity: async (id) => {
      throw new Error(`Delete Not implemented: ${id}`)
    },
  })
}
