import { FC } from 'react'
import { Link } from '@pogokid/mui'

interface LogoProps {
  href?: string
  size?: 'small'
  hideName?: boolean
}

export const Logo: FC<LogoProps> = ({
  href,
  size,
  hideName,
}) => {
  return (
    <Link
      href={href || '/'}
      color="inherit"
      variant="h6"
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        gap: 1,
        textTransform: 'uppercase',
        textDecoration: 'none',
        fontWeight: 600,
        fontSize: size === 'small' ? '10px' : '14px',
      }}
    >
      <img
        src="/soniq-favicon.svg"
        width={24}
        height={24}
        alt="Soniq Studio"
      />
      {!hideName && <span>Soniq</span>}
    </Link>
  )
}
