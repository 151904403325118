import { useSessionContext } from '@pogokid/supabase/react'
import { Optional } from '@pogokid/util'
import {
  ContactWithId,
  ProfileSchema,
} from '@soniq/schema-connection'
import React, {
  createContext,
  FC,
  useContext,
  useMemo,
} from 'react'
import { useCurrentUser, useUserProfile } from '../entityData'

interface ConnectionManagerProviderState {
  /**
   * Is authenticated and has user and settings
   */
  hasUserDetails: boolean
  /**
   * User has an invite and is allowed on the platform
   */
  hasAccess: boolean | null
  isLoadingUserDetails: boolean
  errors: Optional<Error>[]
  userId: Optional<string>
  user: Optional<ContactWithId>
  profileId: Optional<string>
  profile: Optional<ProfileSchema>
  username: string
}

interface ConnectionManagerContextProps
  extends ConnectionManagerProviderState {
  apiBaseUrl: string

  refetch(): void
}

interface ConnectionManagerProviderProps {
  apiBaseUrl: string
}

const ConnectionManagerContext =
  createContext<ConnectionManagerContextProps>({} as any)

export function useConnectionManager() {
  return useContext(ConnectionManagerContext)
}

export const ConnectionManagerProvider: FC<
  React.PropsWithChildren<ConnectionManagerProviderProps>
> = ({ children, apiBaseUrl }) => {
  const { isLoading: isSessionLoading, session } =
    useSessionContext()
  const currentUser = useCurrentUser()
  const userProfile = useUserProfile()

  const value = useMemo((): ConnectionManagerContextProps => {
    const { value: user } = currentUser
    const { value: profile } = userProfile
    const isLoading = Boolean(
      currentUser.loading || userProfile.loading
    )
    return {
      apiBaseUrl,
      hasUserDetails: !isLoading && !!user,
      hasAccess: user ? !!user.invite_id : null,
      isLoadingUserDetails: session
        ? isLoading
        : isSessionLoading,
      errors: [currentUser.error, userProfile.error].filter(
        Boolean
      ),
      userId: user?.id,
      user,
      profileId: profile?.id,
      username: profile?.handle ?? 'me',
      profile,
      refetch() {
        currentUser.refetch()
        userProfile.refetch()
      },
    }
  }, [
    currentUser,
    userProfile,
    apiBaseUrl,
    session,
    isSessionLoading,
  ])

  return (
    <ConnectionManagerContext.Provider value={value}>
      {children}
    </ConnectionManagerContext.Provider>
  )
}
