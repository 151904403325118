import {
  compileValidationSchema,
  makePropertyCleaner,
  schemaProperties,
} from '@pogokid/schema'
import orgSchema from './Org.schema.json'
import { OrgSchema } from '../../.types/Org.schema'
import { compileJsonSchema } from '@pogokid/schema/dynamic'

export const validateOrg = compileValidationSchema<OrgSchema>(
  'OrgSchema',
  compileJsonSchema(orgSchema)
)

export const orgProperties = schemaProperties(orgSchema)
export const cleanOrgProperties =
  makePropertyCleaner<OrgSchema>(orgProperties)
