import {
  compileValidationSchema,
  makePropertyCleaner,
  schemaProperties,
} from '@pogokid/schema'
import { LocationSchema } from '../../.types/Location.schema'
import schema from './Location.schema.json'
import { compileJsonSchema } from '@pogokid/schema/dynamic'

export const validateLocation =
  compileValidationSchema<LocationSchema>(
    'LocationSchema',
    compileJsonSchema(schema)
  )

export const locationProperties = schemaProperties(schema)
export const cleanLocationProperties =
  makePropertyCleaner<LocationSchema>(locationProperties)
