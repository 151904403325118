import { isString, isUuid } from '@pogokid/util/lodash'
import {
  CREATE_CONTACT_ID,
  ExistingStudentContactSchema,
  NewStudentContactSchema,
} from './types'

export function isNewStudentContact(
  schema: any
): schema is NewStudentContactSchema {
  return schema?.contact_id === CREATE_CONTACT_ID
    ? true
    : [
        !isUuid(schema?.contact_id),
        isString(schema?.relationship),
        isString(schema?.name),
      ].every(Boolean)
}

export function isExistingStudentContact(
  schema: any
): schema is ExistingStudentContactSchema {
  return [
    isString(schema?.relationship),
    isString(schema?.contact_id),
    schema?.contact_id !== CREATE_CONTACT_ID,
  ].every(Boolean)
}
