import {
  compileValidationSchema,
  makePropertyCleaner,
  schemaProperties,
} from '@pogokid/schema'
import { ContactRelationshipSchema } from '../../.types/ContactRelationship.schema'
import contactRelationshipSchema from './ContactRelationship.schema.json'
import { compileJsonSchema } from '@pogokid/schema/dynamic'

export const validateContactRelationship =
  compileValidationSchema(
    'ContactRelationship',
    compileJsonSchema(contactRelationshipSchema)
  )

export const contactRelationshipProperties = schemaProperties(
  contactRelationshipSchema
)

export const cleanContactRelationshipProperties =
  makePropertyCleaner<ContactRelationshipSchema>(
    contactRelationshipProperties
  )
