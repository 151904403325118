import {
  useSupabaseClient,
  useUserId,
} from '@pogokid/supabase/react'
import { Optional } from '@pogokid/util'
import { buildEntityHooks } from '@soniq/public-manager'
import {
  AsyncSingleResponse,
  ResponseWithRefetch,
} from '@soniq/public-resource'
import {
  profileByHandle,
  profileByUser,
  profileStore,
  ProfileStore,
} from '@soniq/public-resource-connection'
import {
  ProfileSchema,
  ProfileWithId,
} from '@soniq/schema-connection'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { profileKeys } from '../keys'

export const profileHooks = buildEntityHooks<
  ProfileSchema,
  typeof profileKeys,
  ProfileStore
>(profileKeys, profileStore)

export function useProfileByHandle(
  handle: Optional<string>
): AsyncSingleResponse<ProfileWithId> {
  const db = useSupabaseClient()
  const queryClient = useQueryClient()
  const handleQuery = useQuery({
    enabled: !!handle,
    queryKey: profileKeys.handle(handle),
    queryFn: async () => {
      if (!handle) return null
      const profile = await profileByHandle(db, handle)
      if (profile.data) {
        await queryClient.setQueryData(
          profileKeys.detail(profile.data.id),
          profile
        )
      }
      return profile.data?.id
    },
  })
  const { value, loading, error } = profileHooks.useById(
    handleQuery.data
  )
  return {
    value,
    loading: handleQuery.isLoading || loading,
    error:
      handleQuery.error || (error as Error | null | undefined),
  }
}

export function useUserProfile(): AsyncSingleResponse<ProfileWithId> &
  ResponseWithRefetch {
  const db = useSupabaseClient()
  const userId = useUserId()
  const { data, isLoading, error, refetch } = useQuery({
    enabled: !!userId,
    queryKey: profileKeys.detail(userId),
    initialData: () => null,
    queryFn: async () => {
      return userId ? profileByUser(db, userId) : { data: null }
    },
    staleTime: 0, //24 * 60 * 60_000, // 1 day
    refetchOnMount: 'always',
  })
  return {
    value: data?.data,
    loading: isLoading,
    error: error as Error | null | undefined,
    refetch,
  }
}
