import {
  compileValidationSchema,
  makePropertyCleaner,
  schemaProperties,
} from '@pogokid/schema'
import { ProfileSchema } from '../../.types/Profile.schema'
import { ProfileSettingsSchema } from '../../.types/ProfileSettings.schema'
import schema from './ProfileSettings.schema.json'
import { compileJsonSchema } from '@pogokid/schema/dynamic'

export const validateProfileSettings =
  compileValidationSchema<ProfileSettingsSchema>(
    'ProfileSettingsSchema',
    compileJsonSchema(schema)
  )

export const profileSettingsProperties = schemaProperties(schema)
export const cleanProfileSettingsProperties =
  makePropertyCleaner<ProfileSchema>(profileSettingsProperties)
