import { getFreeAgentContacts } from './contact'

export async function syncContacts(
  accessToken: string,
  userId: string
) {
  try {
    const { contacts } = await getFreeAgentContacts(accessToken)
    if (!userId)
      throw new Error(
        'You need to be logged in to sync contacts'
      )
    // FIXME: removed after moving to supabase
    // for await (let {
    //   first_name,
    //   last_name,
    //   url,
    //   email,
    //   billing_email,
    // } of contacts) {
    //   const contact = await getDocs(
    //     query(
    //       queryContactCollection(db, userId, 500),
    //       where('freeagentUrl', '==', url)
    //     )
    //   )
    //   if (contact.size === 0) {
    //     const contactData: any = {
    //       freeagentUrl: url,
    //       name: `${first_name} ${last_name}`,
    //       email: billing_email || email || null,
    //     }
    //     console.log('Creating contact', contactData)
    //     const doc = await createContact(db, ajv, contactData)
    //     console.log('Created contact', doc.id)
    //   }
    // }
  } catch (err) {
    // console.error('Error syncing contacts', err)
    throw err
  }
}
