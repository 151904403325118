import { SupabaseReadWriteStore } from '@soniq/public-resource/supabase'
import {
  ProfileSettingsSchema,
  validateProfileSettings,
} from '@soniq/schema-connection'

export class ProfileSettingsStore extends SupabaseReadWriteStore<
  'profile_settings',
  ProfileSettingsSchema
> {
  static new() {
    return new ProfileSettingsStore(
      'profile_settings',
      validateProfileSettings
    )
  }
}
