import { KeysOfTypeStrict } from './types'

export function keyBy<T>(
  array: T[],
  key: KeysOfTypeStrict<T, string | number>
): Record<string | number, T> {
  if (!Array.isArray(array)) return {}
  const result = array.reduce((map, item) => {
    const k = item[key] as unknown
    if (typeof k === 'string' || typeof k === 'number') {
      map.set(k, item)
    }
    return map
  }, new Map<string | number, T>())

  return Object.fromEntries(result) as Record<
    KeysOfTypeStrict<T, string>,
    T
  >
}

const x: Array<{ a: string; b: string }> = [
  { a: 'foo', b: 'bar' },
]

keyBy(x, 'a')
