import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import enGB from 'date-fns/locale/en-GB'
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns'
import { FC, PropsWithChildren } from 'react'

interface DateProviderProps {}

export const DateProvider: FC<
  PropsWithChildren<DateProviderProps>
> = ({ children }) => {
  return (
    <LocalizationProvider
      adapterLocale={enGB}
      dateAdapter={AdapterDateFns}
    >
      {children}
    </LocalizationProvider>
  )
}
