import React, { FC, ReactElement, ReactNode } from 'react'
import { Container, Stack, Typography } from '@pogokid/mui'

interface DisplayEmptyProps {
  title: ReactNode
  description?: ReactNode
  actions?: ReactElement<any, any> | null
}

export const DisplayEmpty: FC<DisplayEmptyProps> = ({
  title,
  description,
  actions,
}) => {
  return (
    <Container
      maxWidth={'sm'}
      sx={{ textAlign: 'center', my: 6 }}
    >
      <Stack spacing={2}>
        <Typography variant={'h2'}>{title}</Typography>
        {description}
        {actions && (
          <Stack
            spacing={2}
            direction="row"
            justifyContent="center"
          >
            {actions}
          </Stack>
        )}
      </Stack>
    </Container>
  )
}
