import {
  compileValidationSchema,
  makePropertyCleaner,
  schemaProperties,
} from '@pogokid/schema'
import scheduleSchema from './Schedule.schema.json'
import { ScheduleSchema } from '../../.types/Schedule.schema'
import { cleanScheduleOptions } from './clean'
import { compileJsonSchema } from '@pogokid/schema/dynamic'

export const validateSchedule =
  compileValidationSchema<ScheduleSchema>(
    'ScheduleSchema',
    compileJsonSchema(scheduleSchema),
    cleanScheduleOptions
  )

export const scheduleProperties =
  schemaProperties(scheduleSchema)

export const cleanScheduleProperties =
  makePropertyCleaner<ScheduleSchema>(scheduleProperties)
