import { createContext, FC, useContext, useMemo } from 'react'
import { useSetState } from '@pogokid/react-hooks'
import { useUserId } from '@pogokid/supabase/react'
import { syncContacts } from '~/integrations/freeagent/syncContacts'

interface ContactProviderState {
  isSyncingContacts: boolean
}

interface ContactContextProps extends ContactProviderState {
  syncContacts(accessToken: string): Promise<boolean>
}

interface ContactProviderProps {}

const ContactContext = createContext<ContactContextProps>(
  {} as any
)

export function useContactContext() {
  return useContext(ContactContext)
}

export const ContactProvider: FC<
  React.PropsWithChildren<ContactProviderProps>
> = ({ children }) => {
  const userId = useUserId()
  const [state, setState] = useSetState<ContactProviderState>({
    isSyncingContacts: false,
  })
  const value = useMemo((): ContactContextProps => {
    return {
      ...state,
      syncContacts: async (accessToken: string) => {
        setState((prev) => ({
          isSyncingContacts: true,
        }))
        await syncContacts(accessToken, userId!)
        setState((prev) => ({
          isSyncingContacts: false,
        }))
        return true
      },
    }
  }, [setState, state, userId])
  return (
    <ContactContext.Provider value={value}>
      {children}
    </ContactContext.Provider>
  )
}
