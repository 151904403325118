import { SupabaseClient } from '@pogokid/supabase/react'
import { getPublicConfig } from '~/config'
import { getInfoLogger } from '~/logger'
import { signUpCallbackWithReturnTo } from '~m/auth/paths'

const log = getInfoLogger('ui:signUpFlows')

export type SignUpFlowOptions = {
  type: 'EmailPassword'
  email: string
  password: string
}

export async function signUp(
  db: SupabaseClient,
  options: SignUpFlowOptions
) {
  const { apiBaseUrl } = getPublicConfig()
  switch (options.type) {
    case 'EmailPassword':
      const { error, data } = await db.auth.signUp({
        email: options.email,
        password: options.password,
        options: {
          emailRedirectTo: `${apiBaseUrl}${signUpCallbackWithReturnTo(
            '/getstarted'
          )}`,
        },
      })
      if (error) {
        throw error
      }
      return data
      break
    default:
      throw new Error('Sign up flow type not valid')
      break
  }
}
