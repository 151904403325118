import { isString } from '@pogokid/util/lodash'
import { ScheduleInstanceSchema } from '../../.types/ScheduleInstance.schema'

export function isScheduleInstance(
  data: unknown
): data is ScheduleInstanceSchema {
  return (
    !!data &&
    typeof data === 'object' &&
    [
      'schedule_id' in data && isString(data?.schedule_id),
      'event_start' in data,
      'state' in data && isString(data?.state),
    ].every(Boolean)
  )
}
