import { SupabaseReadWriteStore } from '@soniq/public-resource/supabase'
import {
  LocationSchema,
  validateLocation,
} from '@soniq/schema-connection'

export class LocationStore extends SupabaseReadWriteStore<
  'location',
  LocationSchema
> {
  static new() {
    return new LocationStore('location', validateLocation)
  }
}
