import { Box, Fade } from '@pogokid/mui'
import { Logo } from '~m/AppUi/components/Logo.tsx'

interface AppLoadingOrErrorProps {
  error?: Error
}

export function AppLoadingOrError({
  error,
}: AppLoadingOrErrorProps) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      padding={1}
    >
      <Fade in={true} timeout={3000}>
        <Box data-testid="LoadingOrError">
          {error ? error.message : <Logo />}
        </Box>
      </Fade>
    </Box>
  )
}
