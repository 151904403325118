import { createContext, FC, useContext, useMemo } from 'react'

interface IntegrateProviderState {}

interface IntegrateContextProps extends IntegrateProviderState {
  apiBaseUrl: string
}

interface IntegrateProviderProps {
  apiBaseUrl: string
}

const IntegrateContext = createContext<IntegrateContextProps>({
  apiBaseUrl: 'http://localhost:30041/v1',
})

export function useIntegrateManager() {
  return useContext(IntegrateContext)
}

export const IntegrateManagerProvider: FC<
  React.PropsWithChildren<IntegrateProviderProps>
> = ({ children, apiBaseUrl }) => {
  const value = useMemo((): IntegrateContextProps => {
    return {
      apiBaseUrl,
    }
  }, [apiBaseUrl])

  return (
    <IntegrateContext.Provider value={value}>
      {children}
    </IntegrateContext.Provider>
  )
}
