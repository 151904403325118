import {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  AuthChangeEvent,
  Session,
  User,
  useSessionContext,
  useSupabaseClient,
} from '@pogokid/supabase/react'
import {
  confirmEmailToken,
  type ConfirmEmailTokenOptions,
  ResetPasswordOptions,
  sendResetPasswordForEmail,
  signIn,
  SignInFlowOptions,
} from '~/modules/auth/signInFlows'
import { getAllLogger } from '~/logger'
import {
  reauthenticate,
  ReauthenticateFlowOptions,
} from '~m/auth/reauthenticateFlows'
import { signinPath } from '~m/auth/paths'
import { updatePasswordForUser } from '~m/auth/updateFlows'
import { signUp, SignUpFlowOptions } from './signUpFlows'
import { useQueryClient } from '@tanstack/react-query'

const log = getAllLogger('ui:auth:AuthProvider')

interface AuthProviderState {
  error: Error | null
  isSyncingAuths: boolean
  hasLinkSent?: boolean
  signUpUser: User | null
}

interface AuthContextProps extends AuthProviderState {
  /**
   * Do we have an authenticated user on the current
   * session
   */
  isAuthenticated: boolean
  /**
   * Has the user confirmed their email address
   */
  isConfirmed: boolean
  accessToken: string | undefined
  /**
   * The Supabase/GoTrue user instance
   */
  authUser: User | null

  signUp(options: SignUpFlowOptions): void

  signIn(options: SignInFlowOptions): void

  confirmEmail(options: ConfirmEmailTokenOptions): void

  sendResetPasswordForEmail(options: ResetPasswordOptions): void

  updatePasswordForEmail(newPassword: string): void

  resendEmailConfirmation(email: string): void

  reauthenticate(options: ReauthenticateFlowOptions): void

  signOut(): void
}

interface AuthProviderProps {}

const AuthContext = createContext<AuthContextProps>({} as never)

export function useAuthContext() {
  return useContext(AuthContext)
}

export const AuthProvider: FC<
  React.PropsWithChildren<AuthProviderProps>
> = ({ children }) => {
  const supabase = useSupabaseClient()
  const queryClient = useQueryClient()
  const [state, setState] = useState<AuthProviderState>({
    error: null,
    isSyncingAuths: false,
    signUpUser: null,
  })
  const sessionContext = useSessionContext()

  useEffect(() => {
    function onAuthStateChanged(
      event: AuthChangeEvent,
      session: Session | null
    ) {
      log.verbose('Auth state changed', { event, session })
    }

    supabase.auth.onAuthStateChange(onAuthStateChanged)
  }, [supabase])

  const value = useMemo((): AuthContextProps => {
    return {
      ...state,
      isAuthenticated: !!sessionContext?.session?.user,
      isConfirmed: !!sessionContext?.session,
      accessToken: sessionContext?.session?.access_token,
      authUser: sessionContext?.session?.user ?? null,
      async signUp(options) {
        try {
          setState((prev) => ({
            ...prev,
            error: null,
            isSyncingAuths: true,
            hasLinkSent: false,
          }))
          const { user } = await signUp(supabase, options)
          setState((prev) => ({
            ...prev,
            isSyncingAuths: false,
            hasLinkSent: false,
            signUpUser: user,
          }))
        } catch (err: unknown) {
          log.error('Error signing in', err)
          setState((prev) => ({
            ...prev,
            error: err as Error,
            isSyncingAuths: false,
          }))
        }
      },
      async signIn(options) {
        try {
          setState((prev) => ({
            ...prev,
            error: null,
            isSyncingAuths: true,
            hasLinkSent: false,
          }))
          await signIn(supabase, options)
          if (options.type === 'EmailLink') {
            setState((prev) => ({
              ...prev,
              isSyncingAuths: false,
              hasLinkSent: true,
            }))
          } else {
            setState((prev) => ({
              ...prev,
              isSyncingAuths: false,
              hasLinkSent: false,
            }))
          }
        } catch (err: unknown) {
          log.error('Error signing in', err)
          setState((prev) => ({
            ...prev,
            error: err as Error,
            isSyncingAuths: false,
          }))
        }
      },
      async confirmEmail(options: ConfirmEmailTokenOptions) {
        try {
          setState((prev) => ({
            ...prev,
            error: null,
            isSyncingAuths: true,
          }))
          await confirmEmailToken(supabase, options)
          setState((prev) => ({
            ...prev,
            isSyncingAuths: false,
          }))
        } catch (err: unknown) {
          log.error('Error sending reset password', err)
          setState((prev) => ({
            ...prev,
            error: err as Error,
            isSyncingAuths: false,
          }))
        }
      },
      async sendResetPasswordForEmail(
        options: ResetPasswordOptions
      ) {
        try {
          setState((prev) => ({
            ...prev,
            error: null,
            isSyncingAuths: true,
          }))
          await sendResetPasswordForEmail(supabase, options)
          setState((prev) => ({
            ...prev,
            isSyncingAuths: false,
          }))
        } catch (err: unknown) {
          log.error('Error sending reset password', err)
          setState((prev) => ({
            ...prev,
            error: err as Error,
            isSyncingAuths: false,
          }))
        }
      },
      async updatePasswordForEmail(newPassword: string) {
        try {
          setState((prev) => ({
            ...prev,
            error: null,
            isSyncingAuths: true,
          }))
          await updatePasswordForUser(supabase, newPassword)
          setState((prev) => ({
            ...prev,
            isSyncingAuths: false,
          }))
        } catch (err: unknown) {
          log.error('Error updating password', err)
          setState((prev) => ({
            ...prev,
            error: err as Error,
            isSyncingAuths: false,
          }))
        }
      },
      async resendEmailConfirmation(email: string) {
        try {
          setState((prev) => ({
            ...prev,
            error: null,
            isSyncingAuths: true,
          }))
          const { error } = await supabase.auth.resend({
            type: 'signup',
            email,
          })
          setState((prev) => ({
            ...prev,
            error,
            isSyncingAuths: false,
          }))
        } catch (err: unknown) {
          log.error('Error resending confirmation', err)
          setState((prev) => ({
            ...prev,
            error: err as Error,
            isSyncingAuths: false,
          }))
        }
      },
      async reauthenticate(options) {
        try {
          setState((prev) => ({
            ...prev,
            error: null,
            isSyncingAuths: true,
            hasLinkSent: false,
          }))
          await reauthenticate(supabase, options)
          if (options.type === 'EmailLink') {
            setState((prev) => ({
              ...prev,
              isSyncingAuths: false,
              hasLinkSent: true,
            }))
          }
        } catch (err: unknown) {
          log.error('Error signing in', err)
          setState((prev) => ({
            ...prev,
            error: err as Error,
            isSyncingAuths: false,
          }))
        }
      },
      async signOut() {
        setState((prev) => ({ ...prev, isSyncingAuths: true }))
        await supabase.auth.signOut()
        await queryClient.cancelQueries()
        queryClient.clear()
        // Query Client persister has a throttle of 1000ms
        // which means we need to wait for the above "clear"
        // to persist.
        // See: https://github.com/TanStack/query/discussions/3280
        setTimeout(() => {
          window.location.assign(signinPath())
        }, 1_500)
      },
    }
  }, [state, sessionContext?.session, supabase, queryClient])
  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}
